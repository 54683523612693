import {MESSAGE_TYPE,MESSAGE_STATUS} from "../api/enums.js"
import $emo  from "../api/emotion.js"
import userStore from './userStore';
import  i18n from '@/utils/lang/index.js';
import Vue from 'vue'
import http from '../api/httpRequest.js'

export default {

	state: {
		activeChat: null,
		tempactiveChat:null,//记录上次激活对话，切换其他页面，在进入当前页面时进入改聊天
		chats: [],
		privateMsgMaxId: 0,
		groupMsgMaxId: 0,
		loadingPrivateMsg: false,
		loadingGroupMsg: false,
		
		topmax:0,//置顶最大id，自增长，降序
		topitems:[],//置顶chats
		topAll:[],//所有置顶信息记录，包括删除的对话 ，记录type和targetId

		topmessages:{},//置顶消息  key：P或者G加id  value,置顶消息
	},

	mutations: {
		initChats(state, chatsData) {
			state.chats = chatsData.chats || [];
			state.privateMsgMaxId = chatsData.privateMsgMaxId || 0;
			state.groupMsgMaxId = chatsData.groupMsgMaxId || 0;
			state.topmessages = chatsData.topmessages||{};
			let topmax = 0;
			state.topitems = []
			state.chats.forEach((chat) => {
				if(chat.topnum){
					if(chat.topnum>topmax){
						topmax = chat.topnum
					}
					state.topitems.push(chat) 
				}
				// 防止图片一直处在加载中状态
				chat.messages.forEach((msg) => {
					if (msg.loadStatus == "loading") {
						msg.loadStatus = "fail"
					}
					msg.setTask=null
				})
				chat.delmessages = []
				//初始化之前的聊天  为undefined导致computed监听不到改值变化
				if(!chat.topnum){chat.topnum =  null}//置顶序号
				if(!chat.disturbing){chat.disturbing =  null}//是否免打扰
				if(!chat.lastsendId){chat.lastsendId =  null}
				if(!chat.msgNewMinId){chat.msgNewMinId =  null}
			})
			state.topitems.sort(function(a,b){if(a.topnum < b.topnum){return 1;}else{return -1;}})
			state.topmax = topmax;
		},
		OfflineOpenChat(state, chatInfo){
			this.commit("openChat",chatInfo)
			if(chatInfo.type == 'GROUP' && chatInfo.messageId &&state.groupMsgMaxId < chatInfo.messageId){
				state.groupMsgMaxId = chatInfo.messageId
			}
			if(chatInfo.type == 'PRIVATE' && chatInfo.messageId &&state.privateMsgMaxId < chatInfo.messageId){
				state.privateMsgMaxId = chatInfo.messageId
			}
			for (let idx in state.chats){
				if (state.chats[idx].type == chatInfo.type &&state.chats[idx].targetId === chatInfo.targetId) {
					let chat = state.chats[idx];
					chat.unreadCount =(chat.unreadCount||0) + chatInfo.unreadCount
					chat.lastsendId = chatInfo.lastsendId
					chat.lastContent = chatInfo.lastContent
					chat.messages = []
				}
			}
			this.commit("saveToStorage");
		},
		openChat(state, chatInfo) {
			let chat = null;
			for (let idx in state.chats) {
				if (state.chats[idx].type == chatInfo.type &&
					state.chats[idx].targetId === chatInfo.targetId) {
					chat = state.chats[idx];
					// 放置头部
					this.commit("moveTop", idx)
					break;
				}
			}
			// 创建会话
			if (chat == null) {
				chat = {
					targetId: chatInfo.targetId,
					type: chatInfo.type,
					showName: chatInfo.showName,
					headImage: chatInfo.headImage,
					groupType: chatInfo.groupType || 0,
					disturbing: chatInfo.disturbing,
					lastContent: "",
					msgNewMinId:null,
					lastSendTime: new Date().getTime(),
					lastsendId:null,
					unreadCount: 0,
					messages: [],
					delmessages:[],
					atMe: false,
					atAll: false,
					topnum : null,//是否置顶
				};
				let topinfo = state.topAll.find((ta)=>ta.targetId == chat.targetId&&ta.type == chat.type)
				if(topinfo){
					chat.topnum = topinfo.topnum
					state.topitems.push(chat)
					state.topitems.sort(function(a,b){if(a.topnum < b.topnum){return 1;}else{return -1;}})
				}
				state.chats.unshift(chat);
			}
			this.commit("saveToStorage");
		},
		activeChat(state, idx) {
			if(idx<0){
				state.activeChat = null
			}else{
				state.activeChat = state.chats[idx];
				if(state.activeChat.groupType!='2'){
					state.tempactiveChat = state.activeChat 
				}
				if(state.chats[idx]){
					state.chats[idx].delmessages = []
				}
				//设置激活窗口的置顶消息
				if(state.chats[idx]&&state.chats[idx].messages){
					let datenow = Date.now()
					for (let i = 0; i < state.chats[idx].messages.length; i++) {
						const m = state.chats[idx].messages[i];
						//是否活跃的消息
						if(m.type==MESSAGE_TYPE.GROUP_LIVENESS){
							//大于30秒活跃的信息直接删除
							if(datenow-m.sendTime>30000){
								if(state.chats[idx].messages[i].setTask){
									clearTimeout(state.chats[idx].messages[i].setTask)
								}
								state.chats[idx].messages.splice(i,1)
								i--
							}else{
								//不存在定时任务重新重新计时间
								if(!state.chats[idx].messages[i].setTask){
									this.dispatch("setTimeDelMsg",state.chats[idx].messages[i])
								}
							}
						}
					}
				}
			}
		},
		//置顶的消息 
		topmsg(state, {msgInfo,inform}){
			let key = msgInfo.groupId ?('G'+msgInfo.groupId): (msgInfo.selfSend?'P'+msgInfo.recvId:'P'+msgInfo.sendId)
			if(!state.topmessages[key]){
				Vue.set(state.topmessages,key,[])
			}
			if(state.topmessages[key].findIndex(msg=>msg.id == msgInfo.id)<0){
				state.topmessages[key].push(msgInfo)
			}
			state.topmessages[key].sort(function(a,b){if(a.id > b.id){return 1;}else{return -1;}})
			if(inform){
				let chat = this.getters.findChat(msgInfo);
				if(chat){
					chat.messages.push(inform)
				}
			}
			this.commit("saveToStorage");
		},
		//取消置顶置激活窗口的消息
		cleantopmsg(state,msgInfo){
			let key = msgInfo.groupId ?('G'+msgInfo.groupId): (msgInfo.selfSend?'P'+msgInfo.recvId:'P'+msgInfo.sendId)
			if(state.topmessages[key]){
				let index = state.topmessages[key].findIndex(msg=>msg.id==msgInfo.id)
				if(index>=0){
					//普通成员删除取消记录双向信息
					if(msgInfo.twoway||state.topmessages[key][index].mesTop!=1){
						state.topmessages[key].splice(index,1)
					}else{
						Vue.set(state.topmessages[key][index],'isdelete',true)
					}
				}
			} 
			this.commit("saveToStorage");        
		},
		resetUnreadCount(state, chatInfo) {
			for (let idx in state.chats) {
				if (state.chats[idx].type == chatInfo.type &&
					state.chats[idx].targetId == chatInfo.targetId) {
					state.chats[idx].unreadCount = 0;
					state.chats[idx].atMe = false;
					state.chats[idx].atAll = false;
					state.chats[idx].msgNewMinId = null; 
				}
			}
			this.commit("saveToStorage");
		},
		readedMessage(state, pos) {
			for (let idx in state.chats) {
				if (state.chats[idx].type == 'PRIVATE' &&
					state.chats[idx].targetId == pos.friendId) {
					state.chats[idx].messages.forEach((m) => {
						if (m.selfSend && m.status != MESSAGE_STATUS.RECALL) {
							// pos.maxId为空表示整个会话已读
							if (!pos.maxId || m.id <= pos.maxId) {
								m.status = MESSAGE_STATUS.READED
							}

						}
					})
				}
			}
			this.commit("saveToStorage");
		},
		removeChat(state, idx) {
			let chat = state.chats[idx]
			if (chat == state.activeChat) {
				state.activeChat = null;
			}
			let ind=state.topitems.findIndex(ta=>ta.targetId==chat.targetId,chat.type==chat.type)
			if(ind>=0){
				state.topitems.splice(ind,1)
			}
			state.chats.splice(idx, 1);
			this.commit("saveToStorage");
		},
		clearAllMessage(state,tchat){
			let ind = state.chats.findIndex((ch)=>ch.targetId == tchat.targetId&&ch.type == tchat.type)
			if(ind>=0){
				state.chats[ind].messages = []
			}
		},
		removePrivateChat(state, userId) {
			for (let idx in state.chats) {
				if (state.chats[idx].type == 'PRIVATE' &&
					state.chats[idx].targetId == userId) {
					this.commit("removeChat", idx);
				}
			}
		},
		moveTop(state, idx) {
			// 加载中不移动，很耗性能
			if (state.loadingPrivateMsg || state.loadingGroupMsg) {
				return;
			}
			if (idx > 0) {
				let chat = state.chats[idx];
				state.chats.splice(idx, 1);
				state.chats.unshift(chat);
				this.commit("saveToStorage");
			}
		},
		//置顶
		top(state,idx){
			let chat = state.chats[idx]
			if(chat&&!chat.topnum){
				state.topmax ++
				chat.topnum = state.topmax 
				state.topitems.unshift(chat)
				state.topAll.push({targetId:chat.targetId,type:chat.type,topnum:chat.topnum})
				this.commit("saveToStorage");
			}
		},
		//清楚置顶
		cleantop(state,idx){
			let chat = state.chats[idx]
			if(chat&&chat.topnum){
				chat.topnum = null
				let ind =state.topAll.findIndex(ta=>ta.targetId==chat.targetId,chat.type==chat.type)
				if(ind>=0){
					state.topAll.splice(ind,1)
				}
				let ind2=state.topitems.findIndex(ta=>ta.targetId==chat.targetId,chat.type==chat.type)
				if(ind2>=0){
					state.topitems.splice(ind2,1)
				}
				this.commit("saveToStorage");
			}
		},
		insertMessage(state, msgInfo) {
			//自己的消息不记录最大id
			let selfSend = msgInfo.sendId == userStore.state.userInfo.id
			// 获取对方id或群id
			let type = msgInfo.groupId ? 'GROUP' : 'PRIVATE';
			// 记录消息的最大id
			if (!selfSend&&msgInfo.id && type == "PRIVATE" && msgInfo.id > state.privateMsgMaxId) {
				state.privateMsgMaxId = msgInfo.id;
			}
			if (!selfSend&&msgInfo.id && type == "GROUP"&& msgInfo.id > state.groupMsgMaxId) {
				state.groupMsgMaxId = msgInfo.id;
			}
			// 如果是已存在消息，则覆盖旧的消息数据
			let chat = this.getters.findChat(msgInfo);
			if(!chat){
				return//会话不存在
			}
			msgInfo.state = null;
			//置顶消息
			if(msgInfo.type == MESSAGE_TYPE.MSG_TOP){
				let mes = chat.messages.find(m=>m.id == msgInfo.messageId)
				if(mes){
					mes.selfSend = mes.sendId == userStore.state.userInfo.id
					mes.mesTop = 1
					if(msgInfo.mesTop){
						//是否通知 私聊都通知
						let tip = null
						let  mescontent = $emo.simplemsg(mes)
						if(msgInfo.inform||!msgInfo.groupId){
							tip = {
								msgid:mes.id,
								sendTime: msgInfo.sendTime,
								type: MESSAGE_TYPE.MSG_TOP,
								content:mescontent,
								sendId:msgInfo.sendId,
							}
						}
						this.commit("topmsg",{msgInfo:mes,inform:tip});
					}else{
						mes.twoway =1 //双向取消
						mes&&this.commit("cleantopmsg",mes);
					}
					return
				}
				this.dispatch("setTopMsg",msgInfo)//消息id当前消息列表可能不存在
				return 
			}
			//双向删除消息单条消息
			if(msgInfo.type == MESSAGE_TYPE.RECALL){
				//双向删除对方的消息
				for (let i=0;i<msgInfo.delteteIds.length;i++) {
					chat.delmessages.push({id:msgInfo.delteteIds[i]})
					let inx = chat.messages.findIndex(msg=>msg.id==msgInfo.delteteIds[i]);
					if(inx>=0){
						chat.messages.splice(inx,1)
					}
					//清除置顶
					let key = (type=='GROUP'?'G':'P') + chat.targetId
					if(state.topmessages[key]){
						let topmsg = state.topmessages[key].find(msg=>msg.id==msgInfo.delteteIds[i]);
						if(topmsg){
							topmsg.twoway =1 //双向取消
							this.commit("cleantopmsg",topmsg);
						}
					}
				}
				this.commit("saveToStorage");
				return
			}else if(msgInfo.type == MESSAGE_TYPE.MSG_DELALL){
				//双向删除所有消息
				chat.messages = []
				chat.unreadCount = 0
				//当前会话清空置顶
				let key = (type == 'GROUP'?'G':'P') + chat.targetId
				state.topmessages[key] = []
				this.commit("saveToStorage");
				return
			}
			if(msgInfo.type == MESSAGE_TYPE.GROUP_LIVENESS){
				let contentobj = JSON.parse(msgInfo.content)
				//预存 删除时候day和type判断
				msgInfo.day =contentobj.day
				//删除之前的活跃的消息
				this.commit("deleteMessage",msgInfo);
			}else{
				let message = this.getters.findMessage(chat, msgInfo);
				if (message) {
					Object.assign(message, msgInfo);
					this.commit("saveToStorage");
					return;
				}
				if(msgInfo.type == MESSAGE_TYPE.AUDIO){
					msgInfo.audio_played = null
				}
				chat.lastContent = $emo.simplemsg(msgInfo);
				chat.lastSendTime = msgInfo.sendTime;
				if(msgInfo.type != MESSAGE_TYPE.TIP_TEXT){
					chat.sendNickName = msgInfo.sendNickName;
					chat.lastsendId = msgInfo.sendId
				}else{
					chat.sendNickName = '';
					chat.lastsendId = '';
				}
				// 未读加1
				if (!msgInfo.selfSend && msgInfo.status != MESSAGE_STATUS.READED && msgInfo.type != MESSAGE_TYPE.TIP_TEXT) {
					chat.unreadCount++;
				}
				// 是否有人@我
				if (!msgInfo.selfSend && chat.type == "GROUP" && msgInfo.atUserIds &&
					msgInfo.status != MESSAGE_STATUS.READED) {
					let userId = userStore.state.userInfo.id;
					if (msgInfo.atUserIds.indexOf(userId) >= 0) {
						chat.atMe = true;
					}
					if (msgInfo.atUserIds.indexOf(-1) >= 0) {
						chat.atAll = true;
					}
				}
			}
			if(!chat.msgNewMinId&&msgInfo.id&&msgInfo.type<10){
				chat.msgNewMinId = msgInfo.id
			}
			// 根据id顺序插入，防止消息乱序
			// let insertPos = chat.messages.length;
			// if(msgInfo.id>0){
			// 	for (let idx in chat.messages) {
			// 		if (chat.messages[idx].id && msgInfo.id < chat.messages[idx].id) {
			// 			insertPos = idx;
			// 			console.log(`消息出现乱序,位置:${chat.messages.length},修正至:${insertPos}`);
			// 			break;
			// 		}
			// 	}
			// }
			let insertPos = chat.messages.length;
			if(msgInfo.id>0){
				for (let idx = chat.messages.length -1 ; idx >=0; idx--) {
					if (chat.messages[idx].id){
						if(msgInfo.id > chat.messages[idx].id){
							break
						}else{
							insertPos = idx;
						}
					}
				}
			}
			if(chat.messages.length!=insertPos){
				console.log(`消息出现乱序,位置:${chat.messages.length},修正至:${insertPos}`);
			}else{
				//跨天插入时间显示
				if (chat.messages.length==0||!chat.lastTimeTip || new Date(chat.lastTimeTip).getDate()!=new Date(msgInfo.sendTime).getDate()){
					chat.messages.push({
						sendTime: msgInfo.sendTime,
						type: MESSAGE_TYPE.TIP_TIME,
					});
					chat.lastTimeTip = msgInfo.sendTime;
				}
				insertPos = chat.messages.length;
			}
			chat.messages.splice(insertPos, 0, msgInfo);
			//本地只保留300-500条消息
			if(chat.messages.length>500){
				chat.messages.splice(-300,-1,msgInfo)
			}
			if(msgInfo.type == MESSAGE_TYPE.GROUP_LIVENESS){
				this.dispatch("setTimeDelMsg",msgInfo)
			}
			this.commit("saveToStorage");
		},
		insertBeforeMessage(state, msgInfo) {
			// 获取对方id或群id
			let type = msgInfo.groupId ? 'GROUP' : 'PRIVATE';
			// 记录消息的最大id
			if (msgInfo.id && type == "PRIVATE" && msgInfo.id > state.privateMsgMaxId) {
				state.privateMsgMaxId = msgInfo.id;
			}
			if (msgInfo.id && type == "GROUP" && msgInfo.id > state.groupMsgMaxId) {
				state.groupMsgMaxId = msgInfo.id;
			}
			//如果是已存在消息，则覆盖旧的消息数据
			let chat = this.getters.findChat(msgInfo);
			let message = this.getters.findMessage(chat, msgInfo);
			if(message){
				Object.assign(message, msgInfo);
				// 撤回消息需要显示
				if(msgInfo.type == MESSAGE_TYPE.RECALL){
					chat.lastContent = msgInfo.content;
				}
				this.commit("saveToStorage");
				return;
			}
			//跨天插入时间显示
			let lastTimeTip  = chat.messages[0]&&chat.messages[0].sendTime || null
			if (lastTimeTip && new Date(lastTimeTip).getDate()==new Date(msgInfo.sendTime).getDate()){
				chat.messages.splice(1, 0, msgInfo);
			}else{
				chat.messages.unshift(msgInfo);
				chat.messages.unshift({
					sendTime: msgInfo.sendTime,
					type: MESSAGE_TYPE.TIP_TIME,
				});
				if(!chat.lastTimeTip){
					chat.lastTimeTip = msgInfo.sendTime;
				}
			}
			this.commit("saveToStorage");
		},
		insertMsgTip(state, msgInfo){
			let chat = this.getters.findChat(msgInfo);
			chat.messages.push({
				content:msgInfo.content,
				sendTime: msgInfo.sendTime,
				type: MESSAGE_TYPE.MSGTIP,
			});
		},
		updateMessage(state, msgInfo) {
			// 获取对方id或群id
			let chat = this.getters.findChat(msgInfo);
			let message = this.getters.findMessage(chat, msgInfo);
			if (message) {
				// 属性拷贝
				Object.assign(message, msgInfo);
				this.commit("saveToStorage");
			}
		},
		updateSetMessage(state,{msgInfo,update}){
			// 获取对方id或群id
			let chat = this.getters.findChat(msgInfo);
			let message = this.getters.findMessage(chat, msgInfo);
			if (message) {
				// 属性拷贝
				for (let key in update) {
					Vue.set(message,key,update[key])
				}
				this.commit("saveToStorage");
			}
		},
		deleteMessage(state, msgInfo) {
			let chat = this.getters.findChat(msgInfo);
			if(chat){
				chat.delmessages.push(msgInfo)
				let key = (chat.type=='GROUP'?'G':'P') + chat.targetId
				if(state.topmessages[key]){
					let topmsg = state.topmessages[key].find(msg=>msg.id==msgInfo.id);
					if(topmsg){
						this.commit("cleantopmsg",topmsg);
					}
				}
				for (let idx in chat.messages) {
					// 已经发送成功的，根据id删除
					if (chat.messages[idx].id && chat.messages[idx].id == msgInfo.id) {
						if(chat.messages[idx].type == MESSAGE_TYPE.GROUP_LIVENESS){//删除活跃度
							if(chat.messages[idx].day == msgInfo.day){
								if(msgInfo.setTask){
									clearTimeout(msgInfo.setTask)
								}
								chat.messages.splice(idx, 1);
								break;
							}
						}else{
							chat.messages.splice(idx, 1);
							break;
						}
					}
					// 正在发送中的消息可能没有id，根据发送时间删除
					if (msgInfo.selfSend && chat.messages[idx].selfSend &&
						chat.messages[idx].sendTime == msgInfo.sendTime) {
						chat.messages.splice(idx, 1);
						break;
					}
				}
				this.commit("saveToStorage");
			}
		},
		updateChatFromFriend(state, friend) {
			for (let i in state.chats) {
				let chat = state.chats[i];
				if (chat.type == 'PRIVATE' && chat.targetId == friend.id) {
					chat.headImage = friend.headImage;
					chat.showName = friend.friendRemark || friend.nickName;
					chat.disturbing = friend.disturbing;
					break;
				}
			}
			this.commit("saveToStorage");
		},
		updateChatFromGroup(state, group) {

			for (let i in state.chats) {
				let chat = state.chats[i];
				if (chat.type == 'GROUP' && chat.targetId == group.id) {
					chat.headImage = group.headImage;
					chat.showName = group.remark||group.name;
					chat.disturbing = group.disturbing;
					break;
				}
			}
			this.commit("saveToStorage");
		},

		loadingPrivateMsg(state, loadding) {
			state.loadingPrivateMsg = loadding;
			if (!state.loadingPrivateMsg && !state.loadingGroupMsg) {
				this.commit("sort")
			}
		},
		loadingGroupMsg(state, loadding) {
			state.loadingGroupMsg = loadding;
			if (!state.loadingPrivateMsg && !state.loadingGroupMsg) {
				this.commit("sort")
			}
		},
		sort(state) {
			state.chats.sort((c1, c2) => c2.lastSendTime - c1.lastSendTime);
		},
		saveToStorage(state) {
			let userId = userStore.state.userInfo.id;
			if(userId){
				let key = "chats-" + userId;
				let chatsData = {
					privateMsgMaxId: state.privateMsgMaxId,
					groupMsgMaxId: state.groupMsgMaxId,
					chats: state.chats,
					topAll:state.topAll,
					topmessages:state.topmessages
				}
				localStorage.setItem(key, JSON.stringify(chatsData));
			}
		
		},
		clear(state) {
			state.activeChat = null;
			state.chats = [];
		},
		bjread(state,idx){
			let chat = state.chats[idx];
			if(chat){
				chat.unreadCount = 0;
			}
		},
		bjunread(state,idx){
			let chat = state.chats[idx];
			if(chat){
				chat.unreadCount = 1;
			}
		},
		//设置群签到
		setqiandado(state,{group,datetime}){
			for (let i in state.chats) {
				let chat = state.chats[i];
				if (chat.type == 'GROUP' && chat.targetId == group.id){
					Vue.set(chat,'lastqdtime',datetime)//更新上次签到时间
					chat.messages.push({
						content:i18n.t('comm.signed'),
						sendTime: datetime,
						type: MESSAGE_TYPE.TIP_TEXT,
					});
					this.commit("saveToStorage");
					break
				}
			}
		},
		//更新置顶消息
		updateTopmsg(state,{key,Togmsg}){
			if(!state.topmessages[key]){
				Vue.set(state.topmessages,key,[])
			}else{
				//去除取消的置顶消息
				for (let i = 0; i < state.topmessages[key].length; i++) {
					let tm = state.topmessages[key][i]
					if(tm.mesTop){
						let index = Togmsg.findIndex(tmg=>tmg.id == tm.id)
						if(index<0){
							state.topmessages[key].splice(index,1)
							i--
						}
					}
				}
			}
			//添加新的置顶消息
			Togmsg.forEach(tmsg=>{
				let index = state.topmessages[key].findIndex(tm=>tm.id == tmsg.id)
				if(index<0){
					tmsg.selfSend = Togmsg[0].selfSend == userStore.state.userInfo.id;
					tmsg.mesTop = 1
					state.topmessages[key].push(tmsg)
				}
			})
		},
		//删除列表并从联系人中去除
		deleteChat(state,{type,targetId}){
			let index = state.chats.findIndex((ch)=>{
				return ch.targetId == targetId && ch.type == type
			})
			let topkey = (type=='GROUP'?"G":"P")+targetId
			if(state.topmessages[topkey]){
				state.topmessages[topkey] = undefined
			}
			if(index>=0){
				if(state.activeChat == state.chats[index]){
					state.activeChat = null
				}
				state.chats.splice(index,1)
			}
			this.commit("saveToStorage");
		}
	},
	actions: {
		loadChat(context) {
			return new Promise((resolve, reject) => {
				let userId = userStore.state.userInfo.id;
				let key = "chats-" + userId;
				let item = localStorage.getItem(key)
				if (item) {
					let chatsData = JSON.parse(item);
					context.commit("initChats", chatsData);
				}
				resolve();
			})
		},
		queryTopMsg(context,chat){//查询置顶消息
			let url = ''
			let param = {}
			if(chat.type == 'GROUP'){
				url = '/im/groupMessage/qryGroupTopMes'
				param.groupId = chat.targetId
			}else{
				url = '/im/privateMessage/qryPrivateTopMes'
				param.recvId = chat.targetId
			}
			//查询前3天的指定信息
			param.sendTime =  Math.floor((Date.now() - 3600000 * 24 * 3)/1000)
			http({
				url: url,
				method: 'POST',
				data:param
			}).then(res=>{
				let key = (chat.type=='GROUP'?'G':'P') + chat.targetId
				context.commit("updateTopmsg",{key:key,Togmsg:res});
			})
		},
		//设置置顶消息
		setTopMsg(context,msgInfo){
			let url = '/im/privateMessage/'
			if(msgInfo.groupId){
				url = '/im/groupMessage/'
			}
			http({
				url: url+msgInfo.messageId,
				method: 'GET'
			}).then((mes) => {
				mes.selfSend = mes.sendId == userStore.state.userInfo.id
				mes.mesTop = 1
				if(msgInfo.mesTop){
					//是否通知 私聊都通知
					let tip = null
					let  mescontent = $emo.simplemsg(mes)
					if(msgInfo.inform||!msgInfo.groupId){
						tip = {
							msgid:mes.id,
							sendTime: msgInfo.sendTime,
							type: MESSAGE_TYPE.MSG_TOP,
							content:mescontent,
							sendId:msgInfo.sendId,
						}
					}
					context.commit("topmsg",{msgInfo:mes,inform:tip});
				}else{
					mes.twoway =1 //双向取消
					mes&&context.commit("cleantopmsg",mes);
				}
			})
		},
		//消息30秒后删除
		setTimeDelMsg(context,msgInfo){
			let setTask = setTimeout(()=>{
				context.commit("deleteMessage",msgInfo)
			},30000)
			context.commit("updateMessage",{...msgInfo,setTask:setTask})
		},
	},
	getters: {
		findChatIdx: (state) => (chat) => {
			for (let idx in state.chats) {
				if (state.chats[idx].type == chat.type &&
					state.chats[idx].targetId === chat.targetId) {
					chat = state.chats[idx];
					return idx
				}
			}
		},
		findChat: (state) => (msgInfo) => {
			// 获取对方id或群id
			let type = msgInfo.groupId ? 'GROUP' : 'PRIVATE';
			let targetId = msgInfo.groupId ? msgInfo.groupId : msgInfo.selfSend ? msgInfo.recvId : msgInfo.sendId;
			let chat = null;
			for (let idx in state.chats) {
				if (state.chats[idx].type == type &&
					state.chats[idx].targetId === targetId) {
					chat = state.chats[idx];
					break;
				}
			}
			return chat;
		},
		findMessage: (state) => (chat, msgInfo) => {
			if (!chat) {
				return null;
			}
			for (let idx in chat.messages) {
				// 通过id判断
				if (msgInfo.id && chat.messages[idx].id == msgInfo.id) {
					if(chat.messages[idx].type == MESSAGE_TYPE.GROUP_LIVENESS){//活跃度类型都是一样
						if(chat.messages[idx].day == msgInfo.day){
							return chat.messages[idx];
						}
					}else{
						return chat.messages[idx];
					}
				}
				// 正在发送中的消息可能没有id,通过发送时间判断
				if (!chat.messages[idx].id&&msgInfo.selfSend && chat.messages[idx].selfSend &&chat.messages[idx].sendTime == msgInfo.sendTime) {
					return chat.messages[idx];
				}
			}
		}
	}
}  
import axios from 'axios'
import router from '@/router'
import {Message} from 'element-ui'
import common from '../utils/common.js';
import  i18n  from '@/utils/lang/index.js';

const http = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 1000 * 30,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
	}
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	let accessToken = localStorage.getItem("accessToken");
	if (accessToken) {
		config.headers.Authorization = 'Bearer ' + encodeURIComponent(accessToken);
	}
	const lang = i18n.locale || 'zh_CN'
	let Language={
		zh_CN:"zh",
		en_US:"en",
		hi_IN:"hi"
	}
	config.headers['Accept-Language'] = Language[lang]||'zh'
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(async response => {
	// 登录过期
	if (response.data.code == 200) {
		return response.data.data;
	} else if (response.data.code == 401) {
		if(process.env.VUE_APP_ISNOLOGIN!='1'){
			sessionStorage.removeItem("accessToken");
			location.href = '/';
			return Promise.reject(response.data)
		}else{
			realogin().then(()=>{
				// 重新发送刚才的请求
				http(response.config)
			})
		}
	} else {
		if(response.data.code>-10&&response.data.code<0){
			return Promise.reject(response.data)
		}
		console.log(response.config.url)
		Message({
			message: response.data.msg||response.data.message,
			type: 'error',
			duration: 1500,
			customClass: 'element-error-message-zindex'
		})
		return Promise.reject(response.data)
	}
}, error => {
	if(error.response){
		switch (error.response.status) {
			case 400:
				Message({
					message: error.response.data,
					type: 'error',
					duration: 1500,
					customClass: 'element-error-message-zindex'
				})
				break
			case 401:
				router.replace("/login");
				break
			case 405:
				Message({
					message: i18n.t('request.httpbak'),
					type: 'error',
					duration: 1500,
					customClass: 'element-error-message-zindex'
				})
				break
			case 404:
			case 500:
				Message({
					message: i18n.t('request.netbak'),
					type: 'error',
					duration: 1500,
					customClass: 'element-error-message-zindex'
				})
				break
			case 501:
				Message({
					message: i18n.t('request.netbak'),
					type: 'error',
					duration: 1500,
					customClass: 'element-error-message-zindex'
				})
				break
		}
	}else{
		Message({
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'element-error-message-zindex'
		})
	}

	return Promise.reject(error)
})



export const realogin = ()=>{
	return new Promise(async function(resolve, reject) {
		let deviceFinger = await common.getDeviceFingerprint();
		http({
			url: '/im/imUser/freeLogin',
			method: 'post',
			data: {
				terminal: 0, // APP终端
				userName: deviceFinger,
				password: deviceFinger,
				deviceInfo:await common.getOS()
			}
		}).then(res=>{
			localStorage.setItem("accessToken", res.access_token);
			localStorage.setItem("expires_in", JSON.stringify({expires_in:res.expires_in,ts:Date.now()}));
			return resolve(res)
		})
	});
}


export default http

import Vue from 'vue';
import Vuex from 'vuex';
import chatStore from './chatStore.js';
import friendStore from './friendStore.js';
import userStore from './userStore.js';
import groupStore from './groupStore.js';
import uiStore from './uiStore.js';

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {chatStore,friendStore,userStore,groupStore,uiStore},
	state: {},
	mutations: {
	},
	actions: {
		load(context) {
			return new Promise((resolve, reject)=>{
				this.dispatch("loadUser").then(() => {
					const promises = [];
					promises.push(this.dispatch("loadFriend"));
					promises.push(this.dispatch("loadGroup"));
					promises.push(this.dispatch("loadChat"));
					return Promise.all(promises).then(()=>{
						this.dispatch("clearChat")
						resolve()
					})
				})
			})
		},
		reloadUser(context){
			return new Promise((resolve, reject)=>{
				const promises = [];
				promises.push(this.dispatch("loadFriend"));
				promises.push(this.dispatch("loadGroup"));
				return Promise.all(promises).then(()=>{
					this.dispatch("clearChat")
					resolve()
				})
			})
		},
		clearChat(context){
			for (let i = 0; i < chatStore.state.chats.length; i++) {
				const chat = chatStore.state.chats[i];
				if(chat.type == 'GROUP'){
					let index = groupStore.state.groups.findIndex((gs)=>gs.id == chat.targetId)
					if(index<0){
						this.commit('deleteChat',chat)
					}
				}else if(chat.type == 'PRIVATE'){
					let index = friendStore.state.friends.findIndex((fd)=>fd.id == chat.targetId)
					if(index<0){
						this.commit('deleteChat',chat)
					}
				}
			} 
		},
		unload(context){
			context.commit("clear");
		}
	},
	strict: process.env.NODE_ENV !== 'production'
})


import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
	...zhLocale,
	date:{
		just:"刚刚",
		minutesago:"分钟前",
		yesterday:"昨天",
		Monday:"一",
		Tuesday:"二",
		Wednesday:"三",
		Thursday:"四",
		Friday:"五",
		Saturday:"六",
		Sunday:"日",
	},
	request:{
		httpbak:"http请求方式有误",
		netbak:"服务器出了点小差，请稍后再试",
		serverbak:"服务器不支持当前请求所需要的某个功能",
		quitonline:"您已在其他地方登陆，将被强制下线",
		quitonlinetitle:"强制下线通知",
		disconnected:"连接已断开，尝试重新连接..."
	},
	tiptext:{
		welcome:"欢迎{0}加入群聊",
		joinGroup:"您已加入群聊",
		quitGroup:"您已退出群聊",
		loseGroup:"您已被移出群聊",
		askGroup:"{0}邀请{1}加入了群聊",
	},
	comm:{
		chat:"聊天",
		groupchat:"群聊",
		channel:"频道",
		unreadnum:"{0}条未读",
		search:"搜索",
		addfriend:"添加好友",
		Receivingmsg:"消息接收中...",
		confirm:"确定",
		cancel:"取消",
		confirmDeltitle:"确认解除?",
		confirmDelmsg:"确认要解除与{0}的好友关系吗?",
		jcfriends:"与{0}的好友关系已解除",
		uploading:"上传中...",
		sendfail:"发送失败",
		All:"全部",
		read:"已读",
		Unread:"未读",
		Allread:"全体已读",
		personread:"{0}人已读",
		onSendFail:"该文件已发送失败，目前不支持自动重新发送，建议手动重新发送",
		recall:"撤回",
		delete:"删除",
		top:"置顶",
		atme:"[有人@我]",
		atAll:"[@全体成员]", 
		Reachedtop:"已到达顶部",
		Groupname:"群聊名称",
		GroupRemark:"群聊备注",
		groupgg:"群公告",
		GroupLeader:"群主",
		Remark:"备注",
		noset:"未设置",
		Submit:"提交",
		exitgrouup:"退出群聊",
		Edit:"编辑",
		editsuccess:"修改成功",
		exitgroupmsg:"退出群聊后将不再接受群里的消息，确认退出吗?",
		exitedgroup:"您已退出群聊",
		confirmexit:"确认退出",
		allmembers:"全体成员",
		groupinfo:"群聊信息",
		exitchannel:"退出频道",
		Emoji:"表情",
		Sendpictures:"发送图片",
		sendfiles:"发送文件",
		Receipt:"回执消息",
		Chathistory:"聊天记录",
		send:"发送",
		delmsg:"删除消息",
		confirmdelmsg:"确认删除消息?",
		delsuccess:"删除成功",
		recallmsg:"撤回消息",
		confirmrecallmsg:"确认撤回消息?",
		remsg:"你撤回了一条消息",
		recalledmsg:"消息已撤回",
		exitChannelmsg:"退出频道后将不再接受频道的消息，确认退出吗?",
		exitsuccess:"退出成功",
		fllecw:"文件格式错误，请上传以下格式的文件：",
		filesizemsg:"文件大小不能超过",
		nowonline:"用户当前在线",
		nickname:"昵称",
		sign:"签名",
		sendmessage:"发送消息",
		addfriends:"加为好友",
		AddSuccess:"添加成功，对方已成为您的好友",
		viewinfo:"查看信息",
		Modifyremarks:"修改备注",
		delfriends:"删除好友",
		inputid:"请输入好友ID",
		information:"信息",
		Gender:"性别",
		male:"男",
		female:"女",
		Setting:"设置",
		Avatar:"头像",
		Pertags:"个性签名",
		Plename:"请输入昵称",
		language:"语言",
		usernospeak:"禁言中",
		groupMute:"群已被解除禁言",
		disgroupMute:"群已被禁言",
		userMute:"您已被解除禁言",
		disuserMute:"您已被禁言",
		Offlinetime:"离线时间",

		clearAllTopMsg:"清除所有置顶消息",
		cancelAllTopMsg:"取消所有置顶消息",
		chatlist:"会话列表",
		friend:"好友",
		translateTo:"转发至",
		selFriendNum:"已选{0}个联系人",
		transMsgNum:"转发{0}条信息",
		from:"来自",
		leaveToMsg:"留言",
		showyy:"显示引用",
		hiedyy:"隐藏引用",
		topMsg:"置顶信息",
		jyNotSend:"你已被群主禁言，暂时无法发送消息!",
		reply:"回复",
		sendPlaceholder:"Enter发送，Shift+Enter换行",
		topMsgNum:"{0}条已置顶消息",
		del_onlyme:"仅为我删除信息",
		del_group_infom:"所有人信息中删除",
		del_friend_infom:"为我和对方都删除",
		placeCheckdata:"请先勾选数据！",
		yyNoExist:"引用消息不存在",
		picture:"图片",
		flle:"文件",
		ToXXFriendhistory:"与{0}的聊天记录",
		ToXXGrouphistory:"与{0}的聊天记录",
		copy:"复制",
		CancelTop:"取消置顶",
		transmit:"转发",
		transmitmsg:"转发消息",
		removeGroup:"移出本群",
		setnobb:"禁言",
		setclearbb:"解除禁言",
		download:"下载",
		mutilple:"多选",
		othermsgdelinfo:"他人消息不允许删除",
		TOPMsg_group:"置顶不通知群成员",
		TOPMsg_group_infom:"置顶并通知群成员",
		TOPMsg_friend:"仅置顶在我",
		TOPMsg_friend_infom:"置顶在我和对方",
		TickGroupinfo:"用户{0}踢出群聊",
		userNobb_info:"用户{0}已被禁言",
		userbb_info:"用户{0}已解除禁言",
		copysuc:"复制成功",
		online:"在线",
		showmore:"展开更多",
		hidemore:"收起",
		remark_inputinfo:"群聊的备注仅自己可见",
		notice_inputinfo:"这是一个群的介绍…",
		group_aliasName:"我在本群名称",
		group_info:"群介绍",
		findhistory:"查找聊天记录",
		chatnobb:"消息免打扰",
		chattop:"聊天置顶",
		clearhistory:"清空聊天记录",
		confirm_delAllhistory:"确定是否删除所有聊天记录？ ",
		togetherDel:"同时为{0}删除",
		findGroupUser:"搜索群成员",
		Tobottom:"已到底部...",
		bj_read:"标记已读",
		bj_Noread:"标记未读",
		set_disturb:"消息免打扰",
		clear_disturb:"取消免打扰",
		remove_char:"移除会话列表",
		topped:"已置顶",
		inputInfoCharacters:"请输入{0}-{1}个字符",
		nowUnonline:"用户当前离线",
		countChat:"共{0}个会话",
		setsuccess:"设置成功",
		signtis:"对方很懒，还没有签名哦~",
		myGroupailas:"我的群昵称",
		contacts:"联系人",

		groupdetails:"群聊详情",
		frienddetails:"聊天详情",
		groupuser:"群成员",
		userinfo:"个人信息",
		giftbox:"礼盒",
		sendgiftbox:"发礼盒",
		voice:"语音",
		sending:"发送中...",
		signsuccessful:"签到成功",
		signed:"已签到",
		inputnum:"填写个数",
		inputAmount:"填写数量",
		selectuser:"请选择用户",
		diamond:"钻石",
		piece:"个",
		groupusernum:"群聊人数",
		blessing:"大吉大利",
		notseluser:"未指定领取用户",
		noinputuser:"未填领取用户！",
		nonumerror:"单包数量不能低于1",
		noinputnum:"未填写发送个数",
		noinputAmount:"未填写发送数量",
		robed:"已领取",
		robNomore:"已领取完",
		Expired:"已过期",
		giftrobinfo:"查看领取详情",
		usergift:"{0}的礼盒",
		robNuminfo:"已领取{0}/{1}个,共{2}/{3}数量",
		robgood:"手气最佳",
		norobuser:"非指定用户不能领取！",
		Loading:"加载中..",
		exclusivegift:"专属礼盒",
		notstare:"未开始",
		zhi:"至",
		startdate:"开始日期",
		enddate:"结束日期",
		selectfriend:"选择联系人",
		checkNumfriend:"已勾选{0}位好友",
		invitesuc:"邀请成功",
		now:"今天",
		yesterday:"昨天",
		LastWeek:"最近一周",
		Lastmonth:"最近一个月",
		Lastthreemonths:"最近三个月",
		allmsg:"全部消息",
		Offline:"离线",
		activeNum:"活跃度",
		activelevel:'{0}天活跃度',
		activelevel2:'{0}天活跃度',
		activelevelph:'{0}天活跃度排行',
		recordsendinfo:"按住空格键开始说话，按Esc退出",
		LuckyGiftBox:"运气盲盒",
		ExclusiveGiftBox:"专属礼盒",
		Numberofgift:"盲盒个数",
		FillNumber:"填写个数",
		peoplegroup:"本群共{0}人",
		ExclusiveUser:"专属用户",
		SelectUser:"请选择用户",
		mangbox:"盲盒",
		blindsentout:"{0}发出的盲盒",
		giftopennot:"来晚啦，盲盒已抢完",
		giftopenexpire:"盲盒已过期",
		tiao:"条",
		DiamondGiftBox:"钻石礼盒",
		groupsignin:"群签到",
		signinday:"您已签到{0}天",
		jxsigninday:"连续签到{0}天",
		peoplesigned:"今日签到{0}人",
		signedin:"已签",
		voiceshort:"录音时间太短",
		welcomeback:"欢迎回来",
		welcomeinner:"欢迎回来！请输入您的账号信息",
		account:"账号名",
		mineaccount:"账号",
		inneraccount:"请输入您的帐号名",
		password:"密码",
		innerpassword:"请输入您的密码",
		login:"登 录",
		noaccount:"还没有账号？",
		registernow:"立即注册",
		welcomeregister:"欢迎注册使用",
		passwordtip:"密码可由数字、英文字母组成，长度不少于6个字符",
		confirmpassword:"确认密码",
		pleaseconfirm:"请确认密码",
		registerandlogin:"注册并登录",
		haveaccount:"已有账号？",
		loginnow:"立即登录",
		loingusergc:"长度在 {0} 到 {1} 个字符",
		placerepassword:"请再次输入密码",
		passdifferent:"两次密码不一致",
		loginnameinfo:"账户名必须由字母、‌数字构成",
		loginout:"退出登入",
		loginout_qr:"是否确认退出登入？",
		passtszf:"密码不能包含其它特殊字符",
		recreation:"娱乐",
		groupmanagement:"群管理",
		removemembers:"移除群成员",
		allprohibitedspeech:"全员禁言",
		liftallbans:"解除全员禁言",
		confirmliftallbans:"解除全员禁言,是否确认？",
		liftallbanstips:"全员禁言后该群所有人禁止发送信息,是否确认？",
		tips:"温馨提示",
		removememberstips:"确定将选中的成员从本群中移除吗？",
		remove:"移除",
		membersselected:"已选 {0}个成员",
		searchfriends:"搜索好友",
		administrators:"管理员",
		AccountDetails: "账户明细",
		ExpenditureDiamond:"支出{0}钻石",
		RevenueDiamonds:"收入{0}钻石",
		RedEnvelopeExpired:"过期自动退回",
		comeFrom:"来自",
		sendGroupgift:"发出群礼盒",
		SignReward:"签到奖励",
		myaccount:"我的账户",

		MyDiamonds:"我的钻石",
		YesterdayEarnings:"昨日收益",
		ViewDetails:"查看明细",
		mynosigninfo:"你还没有签名，书写一条吧~"
	}
}

<template>
	<div class="user-info-mask" @click="$emit('close')">
		<div class="user-info" :style="{left: pos.x+'px',top: pos.y+'px'}" @click.stop>
			<div class="user-info-box">
				<div class="avatar">
					<head-image :name="user.nickName" :url="user.headImage" :size="48" :online="user.online"> </head-image>
				</div>
				<div class="user-info-state">
					<span class="username">{{ user.friendRemark||user.nickName }}</span>
					<span v-if="user.online" class="userstate">{{$t('comm.online')}}</span>
				</div>
			</div>
			<div class="user-info-xx">
				<el-descriptions :column="1" title="" class="user-info-items">
					<el-descriptions-item :label="$t('comm.nickname')">{{ user.nickName }}
					</el-descriptions-item>
					<el-descriptions-item :label="$t('comm.sign')">{{ user.signature||$t('comm.signtis')}}
					</el-descriptions-item>
				</el-descriptions>
				<el-divider content-position="center"></el-divider>
			</div>
			<div class="user-btn-group">
				<template  v-if="!isself">
					<el-button v-show="isFriend" class="bt"  @click="onSendMessage()">{{$t('comm.sendmessage')}}</el-button>
					<el-button v-show="!isFriend" class="bt" @click="onAddFriend()">{{$t('comm.addfriends')}}</el-button>	
					<el-button v-show="ishowbt&&!isjy" type="danger" @click="nospack(1)">{{$t('comm.setnobb')}}</el-button>
					<el-button v-show="ishowbt&&isjy"  @click="nospack(0)">{{$t('comm.setclearbb')}}</el-button>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import HeadImage from './HeadImage.vue'

	export default {
		name: "userInfo",
		components: {
			HeadImage
		},
		data() {
			return {

			}
		},
		props: {
			user: {
				type: Object
			},
			pos: {
				type: Object
			},
			isgroup:{
				type:Boolean
			}
		},
		methods:{
			onSendMessage() {
				let user = this.user;
				let chat = {
					type: 'PRIVATE',
					targetId: user.id,
					showName: user.nickName,
					headImage: user.headImage,
					disturbing: user.disturbing,
				};
				this.$store.commit("openChat", chat);
				this.$store.commit("activeChat", 0);
				this.$emit("openfriendchar");
				this.$emit("close");
			},
			onAddFriend() {
				this.$http({
					url: "im/imFriend/add",
					method: "post",
					params: {
						friendId: this.user.id
					}
				}).then((data) => {
					this.$message.success(this.$t('comm.AddSuccess'));
					this.$store.commit("addFriend", this.user);
				})
			},
			nospack(isjy){
				if(this.activeGroup){
					let activeGroup = this.activeGroup
					let userid = this.user.id
					this.$http({
						url: "im/groupMember/modify",
						method: "PUT",
						data: {
							groupId:activeGroup.id,
							userId:userid,
							userMute:isjy,
						}
					}).then((data)=>{
						if(isjy){
							this.$message.success(this.$t('comm.userNobb_info',[this.user.nickName]));
							activeGroup.groupUserMute.push(userid)
						}else{
							this.$message.success(this.$t('comm.userbb_info',[this.user.nickName]));
							let inx = activeGroup.groupUserMute.findIndex(uid=>uid==userid)
							if(inx>=0){
								activeGroup.groupUserMute.splice(inx, 1);
							}
						}
					})
				}
			}
		},
		computed: {
			isFriend() {
				let friends = this.$store.state.friendStore.friends;
				let friend = friends.find((f) => f.id == this.user.id);
				return friend != undefined;
			},
			activeGroup(){
				return this.$store.state.groupStore.activeGroup
			},
			isjy(){
				if(this.activeGroup.groupUserMute){
				  let index =this.activeGroup.groupUserMute.findIndex(userid=>userid==this.user.id)
				  return index>=0
				}
				return false
			},
			mine() {
				return this.$store.state.userStore.userInfo;
			},
			isself(){
				return this.user.id == this.mine.id
			},
			ishowbt(){
				return this.isgroup&&this.activeGroup&&this.activeGroup.ownerId == this.mine.id?true:false
			}
		}
	}
</script>

<style  lang="scss">
	.user-info-mask {
		background-color: rgba($color: #000000, $alpha: 0);
		position: absolute;
		width: 100%;
		height: 100%;

		.user-info {
			position: absolute;
			width: 300px;
		
			background-color: white;
			box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
			border-radius: 8px;
			overflow: hidden;
			font-size: 12px;
			.user-info-box {
				display: flex;
				padding: 22px 20px 11px 20px;
				background:url('~@/assets/im-icons/im-userinfo-bg.svg') ;
				background-size: cover;
				.user-info-state{
					display: flex;
					flex-direction: column;
					margin-left: 20px;
					.username{
						margin-top: 2px;
						font-size: 14px;
						color: #333333;
						line-height: 24px;
					}
					.userstate{
						margin-top: 4px;
						font-size: 12px;
						color: #999999;
						line-height: 16px;
					}
				}
			}
			.user-info-xx{
				margin: 13px 20px 0px 20px;
				.user-info-items {
					white-space: nowrap;
					overflow: hidden;
					margin-bottom:10px;
					.el-descriptions-item{
						padding: 4px;
					}

					.el-descriptions-item__label{
						font-size: 12px;
						color: #999999;
					}
					.el-descriptions-item__content{
						font-size: 12px;
						color: #333333;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						display: inline-block;
					}
				}
				.el-divider{
					margin: 0;
				}
			}
	
			.user-btn-group {
				margin:18px 0px;
				text-align: center;
				.bt{
					background-color: #7678ED;
					color: white;
					&:hover{
						background-color: #6163eb;
					}
				}
			}
		}
	}
</style>
<template>
    <div class="edit-string">
        <span class='title' v-if="title">{{title}}</span>
        <div v-if="!isedit" class="content" :class="{'icon-left':iconalign=='right'}" @click="clickedit">
            <span :class="{'nowrap':iswrap,'iswrap':!iswrap}">{{value?value:(placeholder)}}</span>
            <span v-if="!disabled" style="font-size:20px;"  class="iconfont icon-shuxie"></span>
        </div>
        <input v-if="iswrap"  v-show="isedit" :maxlength="max" ref="strinput" type="text'" :placeholder="placeholder"  v-model="text" @blur="blur" @keyup.enter="keyupenter" />
        <textarea v-else v-show="isedit" :maxlength="max" ref="strinput" :placeholder="placeholder"  v-model="text" @blur="blur" @keyup.enter="keyupenter" ></textarea>
    </div>
</template>


<script>

export default {
	name: "edit-string",
    data() {
        return{
            isedit:false,
            text:""
        }
    },
    props: {
        title: {
            type: String
        },
        value: {
            type: String
        },
        iswrap:{//是否一行显示
            type:Boolean,
            default:true
        },
        max:{
            type: Number,
            default:-1
        },
        min:{
            type: Number,
            default:0
        },
        filed:{
            type: String
        },
        placeholder:{
            type: String
        },
        disabled:{
            type: Boolean,
            default:false
        },
        iconalign:{
            type: String
        }
    },
    create(){
        this.text = this.value
    },
    methods:{
        blur(){
            this.isedit = false
            if(this.text!==this.value){
                //校验合法
                this.text = this.text.trim()
				let strlength = this.text.length
				if(strlength<this.min||(this.max!=-1&&strlength>this.max)){
                    this.$message.error(this.$t('comm.inputInfoCharacters',[this.min,this.max]))
                    this.text = this.value
                    return
				}
                this.$emit("editafter",{filed:this.filed,value:this.text})
            }
        },
        clickedit(){
            if(!this.disabled){
                this.isedit = true
                this.$nextTick(()=>{
                    this.text = this.value
                    this.$refs.strinput.focus()
                })
            }
        },
        keyupenter(){
            this.$refs.strinput.blur()
        }
    }

}
</script>

<style scoped lang="scss">
    .edit-string{
        .title{
            margin-bottom:4px ;
        }
        .iswrap{
            word-break: break-all;
        }
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        display: flex;
        flex-direction: column;
        .span{
            color: #333333;
        }
        .content{
            display: flex;
            &.icon-left{
                flex-direction: row-reverse;
            }
            span{
                color: #999999;
            }
            .nowrap{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .icon-shuxie{
                visibility: hidden;
            }
            &:hover{
                .icon-shuxie{
                    visibility:visible;
                }
            }
        }
        input,textarea{
            width: 100%;
            font-weight: 400;
            color: #999999;
            height: 24px;
            line-height: 24px;
            padding:0px;
            border: none;
            outline: none;
            font-size: 14px;
        }
        textarea{
            height: 100px;
            resize: none; 
            overflow: hidden;
        }
    }

</style>
import http from '../api/httpRequest.js'

export default {

	state: {
		groups: [],
		activeGroup: null,//当前选中群聊  
		activechannel:null,//当前选中频道
	},
	mutations: {
		setGroups(state, groups) {
			state.groups = groups;
		},
		aadGroupChat(state, groupid){
			if(state.groups.length>0){
				let group = state.groups.find(gp=>gp.id == groupid)
				let chat = {
					type: 'GROUP',
					targetId: group.id,
					showName: group.remark||group.name,
					headImage: group.headImage,
					groupType: group.groupType,
					disturbing: group.disturbing
				};
				this.commit("openChat", chat);
			}
		},
		activeGroup(state, idx) {
			state.activeGroup = state.groups[idx];
		},
		activechGroup(state, idx){
			state.activechannel = state.groups[idx];
		},
		addGroup(state, group) {
			state.groups.unshift(group);
		},
		removeGroup(state, groupId) {
			state.groups.forEach((g, idx) => {
				if (g.id == groupId) {
					state.groups.splice(idx, 1);
				}
			})
			if (state.activeGroup && state.activeGroup.id == groupId) {
				state.activeGroup = null;
			}
			if (state.activechannel && state.activechannel.id == groupId) {
				state.activechannel = null;
			}
		},
		updateGroup(state, group) {
			state.groups.forEach((g, idx) => {
				if (g.id == group.id) {
					// 拷贝属性
					Object.assign(state.groups[idx], group);
				}
			})
		},
		setGroupSpeak(state,param){
			if(state.groups.length>0){
				let indx = state.groups.findIndex(gp=>gp.id == param.id)
				state.groups[indx].groupMute = param.groupMute
			}
		},
		setUserGroupSpeak(state,param){
			if(state.groups.length>0){
				let indx = state.groups.findIndex(gp=>gp.id == param.id)
				if(!state.groups[indx].groupUserMute){
					state.groups[indx].groupUserMute=[]
				}
				let index = state.groups[indx].groupUserMute.findIndex(id=>id == param.userid)
				if(index<0&&param.groupMute == 1){
					state.groups[indx].groupUserMute.push(param.userid)
				}else if(index>=0&&param.groupMute != 1){
					state.groups[indx].groupUserMute.splice(index,1)
				}
			}
		},
		clear(state) {
			state.groups = [];
			state.activeGroup = null;
			state.activechannel = null;
		}
	},
	actions: {
		loadGroup(context) {
			return new Promise((resolve, reject) => {
				http({
					url: '/im/imGroup/findGroups',
					method: 'GET'
				}).then((groups) => {
					context.commit("setGroups", groups);
					resolve();
				}).catch((res) => {
					reject(res);
				})
			});
		}
	}
}
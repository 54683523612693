
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Chinese from './zh.js'; //简体中文
import English from './en.js'; //英文
import hi_IN from './hi.js'; //英文
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: defaultlanguage(),
	messages: {
		'zh_CN': Chinese,
		'en_US': English,
		'hi_IN': hi_IN,
	}
});
ElementLocale.i18n((key, value) => i18n.t(key, value))

function defaultlanguage(){
	//系统语言切换，应用语言也切换一次
	let syslang = navigator.language || navigator.userLanguage;
	let beforesyslang = localStorage.getItem("sys_locale")
	if(!beforesyslang){
		beforesyslang = syslang
		localStorage.setItem("sys_locale", syslang)
	}
	if(syslang==beforesyslang){
		let language = localStorage.getItem('locale')
		if(language){
			return language
		}
	}
	let defval =  'en_US';
	if(syslang=='zh-CN'||syslang== 'zh_CN'){
		defval =  'zh_CN'
	}
	if(syslang=='en-US'||syslang== 'en_US'){
		defval =  'en_US'
	}
	if(syslang=='hi_IN'||syslang== 'hi-IN'){
		defval =  'hi_IN'
	}
	localStorage.setItem("sys_locale", syslang)
	localStorage.setItem('locale',defval)
	return defval
}

export default i18n
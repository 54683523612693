let fileClient = null
let lastRefreshTime = null
import OSS from 'ali-oss';
import http from './httpRequest';
const getfileClient = async ()=>{
    let ts = Date.now() 
    if(fileClient&&lastRefreshTime && ts - lastRefreshTime < 1000*60*4){
        return fileClient
    }else{
        lastRefreshTime = ts
        let stsConfig = await http({url: "file/oss/aly",	method: 'GET',})
        // 配置OSS客户端
        const client = new OSS({
            region: stsConfig.region,
            accessKeyId: stsConfig.accessKeyId,
            accessKeySecret: stsConfig.accessKeySecret,
            stsToken: stsConfig.securityToken,
            bucket: stsConfig.bucket,
            secure: true,
            endpoint: stsConfig.ossEndpoint,
            refreshSTSToken: async () => {
                // 这里实现STS凭证的刷新逻辑
                const newCredentials = await http({url: "file/oss/aly",method: 'GET'});
                return {
                    accessKeyId: newCredentials.AccessKeyId,
                    accessKeySecret: newCredentials.AccessKeySecret,
                    stsToken: newCredentials.SecurityToken
                };
            },
            refresh: {
                interval: 1000 * 60 * 20 //20分钟刷新一次凭证
            }
        })
        fileClient = client
        return fileClient
    }
}

const uploadFile = async(file,fileName)=>{
    let fileClient = await getfileClient()
    if(!fileName){
        let hzm = file.name.split('.').pop()
        // 文件名使用乱码+时间戳确保唯一
        fileName = 'xxxxxxxx'.replace(/[xy]/g, (c)=> {var r = Math.random() * 16 | 0,	v = c == 'x' ? r : (r & 0x3 | 0x8);	return v.toString(16);})+'-'+Date.now()+'.'+hzm;
    }
    return new Promise((resolve, reject) => {
        fileClient.put(fileName, file).then((data)=>{
            resolve(data)
        }).catch(e=>{
            fileClient = null
            reject(e)
        })
    })
}

//图片 缩略图参数
const IMG_PEOXESS = "?x-oss-process=image/resize,w_150/quality,q_50"

export {
    getfileClient,
    uploadFile,
    IMG_PEOXESS
}
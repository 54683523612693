import http from '../api/httpRequest.js'
import {TERMINAL_TYPE} from "../api/enums.js"

export default {

	state: {
		friends: [],
		timer: null
	},
	mutations: {
		setFriends(state, friends) {
			state.friends = friends;
		},
		aadFriendChat(state,friendid){//加入到聊天列表
			if(state.friends.length>0){
				let friend = state.friends.find(fd=>fd.id == friendid)
				let chat = {
					type: 'PRIVATE',
					targetId:friend.id,
					showName: friend.friendRemark||friend.nickName,
					headImage: friend.headImage,
					disturbing: friend.disturbing
				};
				this.commit("openChat", chat);
			}
		},
		updateFriend(state,friend){
			state.friends.forEach((f,index)=>{
				if(f.id==friend.id){
					// 拷贝属性
					let online = state.friends[index].online;
					Object.assign(state.friends[index], friend);
					state.friends[index].online =online;
				}
			})
		},
		removeFriend(state, idx) {
			state.friends.splice(idx, 1);
		},
		addFriend(state, friend) {
			//存在不在添加
			if(state.friends.findIndex(fd=>fd.id ==friend.id)<0){
				state.friends.push(friend);
			}
		},
		updateFriendRemark(state,friend){
			state.friends.forEach((f, index) => {
				if (f.id == friend.id) {
					state.friends[index].friendRemark = friend.remark
				}
			})
		},
		refreshOnlineStatus(state){
			if(state.friends.length >0){
				let userIds = [];
				state.friends.forEach((f)=>{userIds.push(f.id)});
				http({
					url: 'im/imUser/terminal/online',
					method: 'post',
					data: {userIds: userIds}
				}).then((onlineTerminals) => {
					this.commit("setOnlineStatus",onlineTerminals);
				})
			}
			// 30s后重新拉取
			clearTimeout(state.timer);
			state.timer = setTimeout(()=>{
				this.commit("refreshOnlineStatus");
			},30000)
		},
		setOnlineStatus(state,onlineTerminals){
			state.friends.forEach((f)=>{
				let userTerminal = onlineTerminals.find((o)=> f.id==o.userId);
				if(userTerminal){
					f.online = true;
					f.onlineTerminals = userTerminal.terminals;
					f.onlineWeb = userTerminal.terminals.indexOf(TERMINAL_TYPE.WEB)>=0
					f.onlineApp = userTerminal.terminals.indexOf(TERMINAL_TYPE.APP)>=0
				}else{
					f.online = false;
					f.onlineTerminals = [];
					f.onlineWeb = false;
					f.onlineApp = false;
				}
			});
			// 在线的在前面
			state.friends.sort((f1,f2)=>{
				if(f1.online&&!f2.online){
					return -1;
				}
				if(f2.online&&!f1.online){
					return 1;
				}
				return 0;
			});
		},
		clear(state) {
			clearTimeout(state.timer);
			state.friends = [];
			state.timer = null;
			state.activeFriend = [];
		}
	},
	actions: {
		loadFriend(context) {
			return new Promise((resolve, reject) => {
				http({
					url: '/im/imFriend/list',
					method: 'GET'
				}).then((friends) => {
					context.commit("setFriends", friends);
					context.commit("refreshOnlineStatus");
					resolve()
				}).catch((res) => {
					reject();
				})
			});
		}
	}
}

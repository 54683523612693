<template>
  <div class="login">
    <div class="loginContent">
      <div class="signWrap">
        <div class="signWrap_mask">
          <div class="left">
            <div class="leftRound">
              <div class="leftLogo"></div>
            </div>
          </div>
          <div class="right">
            <div class="rightLang">
              <span class="iconfont icon-yuyan" style="color: #5B5C95;font-size: 16px"></span>
              <el-select v-model="lang"  @change="languagechange" size="mini">
                <el-option label="简体中文" value="zh_CN"></el-option>
                <el-option label="English" value="en_US"></el-option>
                <el-option label="हिंदी" value="hi_IN"></el-option>
              </el-select>
            </div>
            <div class="rightBody" :style="viewType?'margin: 107px auto;':'margin: 70px auto;'">
              <!-- 登入 -->
              <template v-if="viewType">
                <div class="titleWrap">
                  {{$t('comm.welcomeback')}}
                </div>
                <div class="titleSubWrap">
                  {{$t('comm.welcomeinner')}}
                </div>
                <el-form ref="form" label-position="top" :model="loginForm" :rules="loginRules" :hide-required-asterisk="true">
                  <div class="account-password">
                    <el-form-item :label="$t('comm.account')" prop="userName">
                      <el-input
                          v-model="loginForm.userName"
                          :placeholder="$t('comm.inneraccount')">
                        <div slot="prefix" class="prefix-icon">
                          <span class="iconfont icon-xianshirenyuan" style="color: #5B5C95;font-size: 16px"></span>
                        </div>
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('comm.password')" prop="password" >
                      <el-input
                          v-model="loginForm.password"
                          :placeholder="$t('comm.innerpassword')"
                          :type="flag?'text':'password'">
                        <div slot="prefix" class="prefix-icon prefix-small">
                          <span class="iconfont icon-mima" style="color: #5B5C95;font-size: 16px"></span>
                        </div>
                        <div slot="suffix" class="suffix-icon suffix-small">
                          <span class="iconfont" :class="flag?'icon-yincang':'icon-xianshi'" style="color: #5B5C95;font-size: 16px" @click="flag=!flag"></span>
                        </div>
                      </el-input>
                    </el-form-item>
                  </div>
                </el-form>
                <el-button  class="submitBtn"  :loading="loading"   @click="handleLogin"> {{$t('comm.login')}} </el-button>
                <div class="rightFooter">
                  <div class="rightFooterLeft">{{$t('comm.noaccount')}}</div>
                  <div class="rightFooterRight" @click="viewTypechange">{{$t('comm.registernow')}}</div>
                </div>
              </template>
              <!-- 注册 -->
              <template v-else>
                <div class="titleWrap">
                  {{$t('comm.welcomeregister')}}
                </div>
                <div class="titleSubWrap">
                  {{$t('comm.passwordtip')}}
                </div>
                <el-form ref="zcform" label-position="top" :model="registerForm" :rules="registerRules" :hide-required-asterisk="true">
                  <div class="account-password">
                    <el-form-item :label="$t('comm.account')" prop="userName">
                      <el-input
                          v-model="registerForm.userName"
                          :placeholder="$t('comm.inneraccount')">
                        <div slot="prefix" class="prefix-icon">
                          <span class="iconfont icon-xianshirenyuan" style="color: #5B5C95;font-size: 16px"></span>
                        </div>
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('comm.password')" prop="password" >
                      <el-input
                          v-model="registerForm.password"
                          :placeholder="$t('comm.innerpassword')"
                          :type="flag?'text':'password'">
                        <div slot="prefix" class="prefix-icon prefix-small">
                          <span class="iconfont icon-mima" style="color: #5B5C95;font-size: 16px"></span>
                        </div>
                        <div slot="suffix" class="suffix-icon suffix-small">
                          <span class="iconfont" :class="flag?'icon-yincang':'icon-xianshi'" style="color: #5B5C95;font-size: 16px" @click="flag=!flag"></span>
                        </div>
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('comm.confirmpassword')" prop="confirmPassword">
                      <el-input
                          v-model="registerForm.confirmPassword"
                          :placeholder="$t('comm.pleaseconfirm')"
                          :type="confirmFlag?'text':'password'">
                        <div slot="prefix" class="prefix-icon prefix-small">
                          <span class="iconfont icon-mima" style="color: #5B5C95;font-size: 16px"></span>
                        </div>
                        <div slot="suffix" class="suffix-icon suffix-small">
                          <span class="iconfont" :class="confirmFlag?'icon-yincang':'icon-xianshi'" style="color: #5B5C95;font-size: 16px" @click="confirmFlag=!confirmFlag"></span>
                        </div>
                      </el-input>
                    </el-form-item>
                  </div>
                </el-form>
                <el-button  class="submitBtn"  :loading="loading"   @click="handleregister">  {{$t('comm.registerandlogin')}} </el-button>
                <div class="rightFooter">
                  <div class="rightFooterLeft">{{$t('comm.haveaccount')}}</div>
                  <div class="rightFooterRight" @click="viewTypechange">{{$t('comm.loginnow')}}</div>
                </div>
              </template>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="loginFooter">
    </div>
  </div>
</template>

<script>

import i18n from "@/utils/lang";
import common from '../utils/common.js';

export default {
  name: "Login",
  data() {
    return {
      flag: false,
      confirmFlag: false,
      lang:null,
      viewType: true, //是否注册
      loginForm: {
        userName: "",
        password: "",
      },
      registerForm: {
        userName: "",
        password: "",
        confirmPassword:""
      },
      loading: false,
    }
  },
  created() {
    if(process.env.VUE_APP_ISNOLOGIN=='1'){
      this.$router.push("/home");
      return
    }
    this.loginForm.userName = localStorage.getItem("userName")||"";
    this.lang = localStorage.getItem('locale') || 'zh_CN';
  },
  computed:{
    loginRules(){
      return {
        userName: [{ required: true, trigger: "blur", message:this.$t('comm.inneraccount')}],
        password: [{ required: true, message: this.$t('comm.innerpassword'), trigger: "blur" }]
      }
    },
    registerRules(){
      return {
        userName: [{ required: true, trigger: "blur", message: this.$t('comm.inneraccount')},
              { pattern: /^[a-zA-Z0-9]+$/, message:this.$t('comm.loginnameinfo'), trigger: "blur" },
              { min: 6, max: 20, message: this.$t('comm.loingusergc',['6','20']), trigger: "blur"}
            ],
        password: [
          { required: true, message: this.$t('comm.innerpassword'), trigger: "blur" },
          { pattern: /^[a-zA-Z0-9~!@#$%^&*()_+-.]+$/, message:this.$t('comm.passtszf'), trigger: "blur" },
          { min: 6, max: 16, message: this.$t('comm.loingusergc',['6','16']), trigger: "blur" }],
        confirmPassword: [
          { required: true, message: this.$t('comm.placerepassword'), trigger: "blur" },
          {validator: (rule, value, callback) => {
              if (this.registerForm.password !== value) {
                callback(new Error(this.$t('comm.passdifferent')));
              } else {
                callback();
              }
            }, trigger: "blur" }]
      }
    },
  },
  methods: {
    viewTypechange(){
      this.viewType = !this.viewType
      this.confirmFlag = false
      this.flag = false
      this.$nextTick(()=>{
        if(this.viewType){
          this.$refs.form.clearValidate()
        }else{
          this.$refs.zcform.clearValidate()
        }
      })
    },
    languagechange(){
      i18n.locale = this.lang
      localStorage.setItem('locale',this.lang)
      this.$nextTick(()=>{
        if(this.viewType){
          this.$refs.form.clearValidate()
        }else{
          this.$refs.zcform.clearValidate()
        }
      })
    },
    handleLogin(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.login(this.loginForm)
        }
      })
    },
    handleregister(){
      this.$refs.zcform.validate((valid) => {
        if (valid) {
          this.registers(this.registerForm)
        }
      })
    },
    async login(loginForm){
        this.loading = true
				let param = {
					terminal:this.$enums.TERMINAL_TYPE.WEB, // APP终端
					userName:loginForm.userName,
					password:loginForm.password,
					deviceInfo:await common.getOS()
				}
				this.$http({
					url: '/im/imUser/login',
					data: param,
					method: 'POST'
				}).then(res => {
					console.log("登录成功,自动跳转到聊天页面...")
					localStorage.setItem("userName", param.userName);
					localStorage.setItem("accessToken", res.access_token);
			    localStorage.setItem("expires_in", JSON.stringify({expires_in:res.expires_in,ts:Date.now()}));
					if(this.$route.query&&this.$route.query.gid){
            this.$router.push("/home?gid="+this.$route.query.gid);
					}else{
            this.$router.push("/home");
					}
				}).finally(() => {
          this.loading = false
				})
			},
			registers(registerForm){
				let param = {
					userName:registerForm.userName,
					password:registerForm.password
				}
        this.loading = true
				this.$http({
					url: '/im/imUser/register',
					data: param,
					method: 'POST'
				}).then(data => {
					this.login(param)
				}).finally(()=>{
          this.loading = false
				})
			},
  }
}
</script>
<style lang="scss" scoped>
$theme-color: #297bff;
.flexWrap {
  display: flex;
}

.flexWrap .button {
  font-size: 14px;
  cursor: pointer;
}

.flexWrap .button,
.flexWrap .time {
  width: 150px;
  background: $theme-color;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
::v-deep .el-checkbox {
  &:hover {
    .el-checkbox__inner {
      border-color: $theme-color !important;
    }
  }
  .el-checkbox__label:hover {
    color: $theme-color;
  }
  &.is-checked {
    .el-checkbox__label {
      color: $theme-color;
    }
    .el-checkbox__inner {
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }
  .flexWrap {
    width: 150px;
    background: $theme-color;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    margin-left: 10px;
  }
}

.flexWrap .time {
  font-size: 10px;
}

.tip {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666;
}

.el-form {
  .el-form-item {
    ::v-deep {
      margin-bottom: 16px;
      .el-input__prefix {
        height: 100%;
        left: 8px;
        transition: all .3s;

      }
      .el-input__suffix {
        height: 100%;
        right: 13px;
        transition: all .3s;
        pointer-events: none;
      }
      .el-input__inner {
        padding-left: 32px;
        font-size: 14px;
      }
      .el-input__inner::placeholder,
      .el-textarea__inner::placeholder {
        color: rgba(91,92,149,0.6);
      }

      .el-form-item__label {
        font-size: 16px;
        color: #5B5C95;
        padding: 0 0 4px 0;
        line-height: 20px;
      }

      input {
        height: 40px;
        border-radius: 8px;
        border: 1px solid #B5AFD0;
        background: transparent;

      }
      .el-select {
        width: 100%;
        .el-input {
          .el-select__caret{
            color: #666666;
            font-size: 14px;
          }
        }
      }

    }
  }
}

.login {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #DBDCFF;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: auto;

}

.login ::-webkit-scrollbar {
  width: 4px;
}

.login ::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #b7b7b7;
  border-radius: 2px;
}

.loginContent {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  min-height: 600px;
  height: 100%;
  box-sizing: border-box;
}

.login .signWrap {
  display: flex;
  width: 1264px;
  height: 706px;

  background: url(~@/assets/image/login_bg.svg) no-repeat;
  background-position: center;
  background-size: 102% 100%;
  border-radius: 40px;
  box-sizing: border-box;
  position: relative;
  .signWrap_mask{
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    background-color:rgba(255,255,255,0.3);
    position: absolute;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
  }
}

.login .signWrap .left {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 632px;
  color: #fff;
  /*background: #DBDCFF;*/
  background-size: cover;
  box-sizing: border-box;
  .leftRound {
    width: 200px;
    height: 200px;
    margin: 217px auto ;
    border-radius: 50%;
    background:RGBA(120,122,237,0.3);
    position: relative;
    &::before{
      content: "";
      display: inline-block;
      width: 344px;
      height: 172px;
      background-image: radial-gradient(circle at 50% 0%, #c2c4f9 70px, #d5d9f7 130px, RGBA(120, 122, 237, 0) 172px);
      margin-top: 100px;
      margin-left: -72px;
      border-radius: 0 0 172px 172px;
    }
    .leftLogo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 112px;
      height: 112px;
      background: url(~@/assets/image/logo.svg) no-repeat;
      background-size: 100% 100%;
    }
  }
  .form-title {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 360px;
    text-indent: 16px;
    margin-bottom: 0;
    font-weight: bold;
    color: #e8efff;
    height: 64px;
    line-height: 64px;
    background: rgba(17, 76, 189, 0.6);
  }
}

.login .signWrap .left img {
  width: 53px;
  margin: 0 0 20px 8px;
}

.login .signWrap .left h4 {
  font-size: 24px;
  font-weight: border;
  letter-spacing: 1.4px;
}

.login .signWrap .right {
  flex: 1;
  width: 632px;
  background-color: rgba(255, 255, 255, 0.6);
  .rightLang {
    display: flex;
    margin: 40px 42px 0px 512px;
    height: 16px;
    .el-select{
      width: 100px;
      ::v-deep{
        input{
          text-align: center;
          border: none;
          width: 80px;
          height: 16px;
          padding: 0;
          font-weight: 500;
          font-size: 12px;
          color: #5B5C95;
          padding-right: 25px;
          background: transparent;
        }
        .el-input__suffix{
          right: 0;
        }
        .el-input__icon{
          line-height: 16px;
          font-weight: 600;
          color: #5B5C95;
        }
      }
    }
  }
  .rightBody {
    width: 320px;
    .titleWrap {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 28px;
      color: #5B5C95;
      height: 40px;
      line-height: 40px;
    }
    .titleSubWrap {
      font-size: 14px;
      color: #5B5C95;
      height: 20px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .submitBtn {
      margin-top: 32px;
      width: 320px;
      height: 48px;
      background: #7678ED;
      border-radius: 8px;
      opacity: 0.6;
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
    }

    .rightFooter {
      display: flex;
      margin-top: 32px;
      justify-content: center;
      height: 20px;
      .rightFooterLeft {
        font-weight: 400;
        font-size: 12px;
        color: rgba(91,92,149,0.6);
        line-height: 20px;
      }
      .rightFooterRight {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        color: #5B5C95;
        line-height: 20px;
      }
    }
  }
}


.login .signWrap .right .flexWrap {
  width: 100%;
  display: flex;
}


.login .signWrap .right .flexWrap .flex {
  width: 0;
  flex: 1;
}

.loginFooter {
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #333;
}

.loginFooter a,
.loginFooter span {
  color: inherit;
  text-decoration: none;
}

.loginFooter a :not(:last-child):after,
.loginFooter span :not(:last-child):after {
  display: inline-block;
  content: " Â· ";
  white-space: pre;
}

.prefix {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prefix-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
  }
}

.prefix-small img {
  width: 18px;
}
:export {
  theme: $theme-color;
}
::v-deep.el-input--medium .el-input__inner {
  width: 251px !important;
}
.account-password {
  ::v-deep.el-input--medium .el-input__inner {
    width: 363px !important;
  }
}
</style>

<style lang="scss">
$theme-color: #00c17a;
.forget-dialog {
  .el-form-item.is-error .el-input__inner,
  .el-form-item.is-error .el-input__inner:focus,
  .el-form-item.is-error .el-textarea__inner,
  .el-form-item.is-error .el-textarea__inner:focus .el-input__inner {
    border-color: #f47084 !important;
  }
  .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
  .el-button--primary {
    color: #fff;
    background-color: $theme-color !important;
    border-color: $theme-color !important;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
    }
  }
  .el-button--default {
    &:hover {
      color: $theme-color;
      opacity: 0.8;
      border-color: $theme-color !important;
    }
    &:active,
    &:focus {
      color: $theme-color;
      border-color: $theme-color !important;
      opacity: 1;
    }
  }
}

.flexWrap {
  width: 100%;
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-shrink {
  flex-shrink: 0;
}
.code-btn {
  height: 32px;
  margin-left: 12px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../view/Home'
import Login from '../view/login'
// 安装路由
Vue.use(VueRouter);


// 配置导出路由
const router = new VueRouter({
  routes: [{
      path: "/", 
      redirect: "/home"
    },{
		  name: "login",
		  path: '/login',
		  component: Login,
	},{
      path: '/home',
      component: Home,
	  children:[
		{
		    path: "/",
			redirect: "/home/chat" 
		},
		{
		    name: "Chat",
		    path: "/home/chat",
		    component: () => import("../view/Chat"),
		},
		{
		  	path: "/home/friend",
		  	component: () => import("../view/Friend"),
		},
		{
		  	path: "/home/channel",
		  	component: () => import("../view/Channel"),
		}
	  ]
    }
  ]
});

//解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
if(process.env.VUE_APP_ISNOLOGIN!='1'){
	const whiteList = ['/login']// 在免登录白名单，直接进入
	router.beforeEach((to, from, next) => {
		let accessToken = localStorage.getItem('accessToken')
		if(accessToken){
			if (to.path === '/login') {
				next({ path: '/' })
			}
			next()
		}else{
			// 没有token
			if (whiteList.indexOf(to.path) !== -1) {
				next()
			}else{
				//保留链接参数 登入后代入
				let param =""
				if(to.query&&to.query.gid){
					param = "?gid="+to.query.gid
				}
				next(`/login${param}`) // 否则全部重定向到登录页
			}
		}
	})
}
export default router
import enLocale from 'element-ui/lib/locale/lang/en'
export default {
	...enLocale,
	date:{
		just:"just",
		minutesago:"minutes ago",
		yesterday:"yesterday",
		Monday:"MOM",
		Tuesday:"TUE",
		Wednesday:"WED",
		Thursday:"THU",
		Friday:"FRI",
		Saturday:"SAT",
		Sunday:"SUN",
	},
	request:{
		httpbak:"Incorrect HTTP request method",
		netbak:"There was a slight error with the server. Please try again later",
		serverbak:"The server does not support a certain feature required for the current request",
		quitonline:"You have logged in elsewhere and will be forcibly taken offline",
		quitonlinetitle:"Forced offline notification",
		disconnected:"Connection disconnected, trying to reconnect..."
	},
	tiptext:{
		welcome:"Welcome {0} to join the group chat",
		joinGroup:"You have joined the group chat",
		quitGroup:"You have exited the group chat",
		loseGroup:"You have been removed from the group chat",
		askGroup:"{0} invited {1} to join the group chat",
	},
	comm:{
		chat:"Chat",
		groupchat:"Group chat",
		channel:"Channel",
		unreadnum:"{0} unread entry",
		search:"search",
		addfriend:"Add friends",
		Receivingmsg:"Receiving messages...",
		confirm:"confirm",
		cancel:"cancel",
		confirmDeltitle:"Confirm termination?",
		confirmDelmsg:"Are you sure you want to delete the friend relationship with {0}?",
		jcfriends:"Friendly relationship with {0} has been terminated",
		uploading:"Uploading...",
		sendfail:"fail in send",
		All:"All",
		read:"Read",
		Unread:"Unread",
		Allread:"All read",
		personread:"{0} person read",
		onSendFail:"The file has failed to be sent and does not currently support automatic resending. It is recommended to manually resend it",
		recall:"Recall",
		delete:"Delete",
		top:"Top",
		atme:"[Someone @ me]",
		atAll:"[Someone @ all]",
		Reachedtop:"Reached the top",
		Groupname:"Group chat name",
		GroupRemark:"Group chat notes",
		groupgg:"Group announcement",
		GroupLeader:"Group Leader",
		Remark:"Remark",
		noset:"Not set",
		Submit:"Submit",
		exitgrouup:"Exit group chat",
		Edit:"Edit",
		editsuccess:"Modified successfully",
		exitgroupmsg:"After exiting the group chat, you will no longer receive messages from the group. Are you sure you want to exit?",
		exitedgroup:"You have exited the group chat",
		confirmexit:"Confirm exit",
		allmembers:"All members",
		groupinfo:"Group chat information",
		exitchannel:"Exit Channel",
		Emoji:"Emoji",
		Sendpictures:"Send pictures",
		sendfiles:"send files",
		Receipt:"Receipt message",
		Chathistory:"Chat history",
		send:"Send",
		delmsg:"delete message",
		confirmdelmsg:"Are you sure to delete the message?",
		delsuccess:"Delete successful",
		recallmsg:"recall message",
		confirmrecallmsg:"Confirm the recall message?",
		remsg:"You withdrew a message",
		recalledmsg:"Message has been recalled",
		exitChannelmsg:"After exiting the channel, you will no longer receive messages from the channel. Are you sure you want to exit?",
		exitsuccess:"Exit successful",
		fllecw:"The file format is incorrect. Please upload a file in the following format:",
		filesizemsg:"The file size cannot exceed",
		nowonline:"The user is currently online",
		nickname:"nickname",
		sign:"sign",
		sendmessage:"send message",
		addfriends:"Add friends",
		AddSuccess:"Successfully added, the other party has become your friend",
		viewinfo:"see information",
		Modifyremarks:"Modify remarks",
		delfriends:"Delete friends",
		inputid:"Please enter your friend ID",
		information:"information",
		Gender:"Gender",
		male:"male",
		female:"female",
		Setting:"Setting",
		Avatar:"Avatar",
		Pertags:"Personalized tags",
		Plename:"Please enter your nickname",
		language:"language",
		usernospeak:"In prohibition",
		groupMute:"The group has been lifted from the ban",
		disgroupMute:"The group has been banned from speaking",
		userMute:"You have been lifted from the ban",
		disuserMute:"You have been banned from speaking",
		Offlinetime:"Offline time",

		clearAllTopMsg:"Clear all pinned messages",
		cancelAllTopMsg:"Cancel all pinned messages",
		chatlist:"Session List",
		friend:"Friend",
		translateTo:"Forward to",
		selFriendNum:"{0} contacts selected",
		transMsgNum:"Forward {0} messages",
		from:"from",
		leaveToMsg:"Message",
		showyy:"Show references",
		hiedyy:"Hide references",
		topMsg:"Top information",
		jyNotSend:"You have been banned by the group leader and are currently unable to send messages!",
		reply:"Reply",
		sendPlaceholder:"Enter send, Shift+Enter line break",
		topMsgNum:"{0} pinned messages",
		del_onlyme:"Delete information for me only",
		del_group_infom:"Delete from everyone's information",
		del_friend_infom:"Delete for both me and the other party",
		placeCheckdata:"Please check the data first!",
		yyNoExist:"The reference message does not exist",
		picture:"Picture",
		flle:"File",
		ToXXFriendhistory:"Chat history with {0}",
		ToXXGrouphistory:"chat history with {0}",
		copy:"Copy",
		CancelTop:"Cancel pinned",
		transmit:"Forward",
		transmitmsg:"Forward message",
		removeGroup:"Remove from this group",
		setnobb:"Prohibition",
		setclearbb:"Release the ban",
		download:"Download",
		mutilple:"Multiple selection",
		othermsgdelinfo:"Deleting messages from others is not allowed",
		TOPMsg_group:"Top without notifying group members",
		TOPMsg_group_infom:"Top and notify group members",
		TOPMsg_friend:"Top me only",
		TOPMsg_friend_infom:"Top me and the other person",
		TickGroupinfo:"User {0} kicked out of group chat",
		userNobb_info:"User {0} has been banned from speaking",
		userbb_info:"User {0} has lifted the ban",
		copysuc:"Copy successful",
		online:"Online",
		showmore:"Expand More",
		hidemore:"Put it away",
		remark_inputinfo:"The group chat notes are only visible to oneself",
		notice_inputinfo:"This is an introduction to a group...",
		group_aliasName:"I am in this group name",
		group_info:"Group Introduction",
		findhistory:"Find chat records",
		chatnobb:"Message without disturbance",
		chattop:"Top of Chat",
		clearhistory:"Clear chat history",
		confirm_delAllhistory:"Are you sure you want to delete all chat records? ",
		togetherDel:"Simultaneously delete for {0}",
		findGroupUser:"Search for group members",
		Tobottom:"At the bottom...",
		bj_read:"Mark as read",
		bj_Noread:"Mark unread",
		set_disturb:"Message is not disturbed",
		clear_disturb:"Cancel No Disturbance",
		remove_char:"Remove session list",
		topped:"Topped",
		inputInfoCharacters:"Please enter {0}- {1} Characters",
		nowUnonline:"The user is currently offline",
		countChat:"{0} sessions in total",
		setsuccess:"Set successfully",
		signtis:"The other party is very lazy and hasn't signed yet~",
		myGroupailas:"My group nickname",
		contacts:"Contact",

		groupdetails:"Group chat details",
		frienddetails:"Chat details",
		groupuser:"Group member",
		userinfo:"Personal Information", 
		giftbox:"Gift box",
		sendgiftbox:"Send Gift Box",
		voice:"Voice",
		sending:"Sending...",
		signsuccessful:"Check in successful",
		signed:"Signed in",
		inputnum:"Fill in the number",
		inputAmount:"Fill in quantity",
		selectuser:"Please select a user",
		diamond:"Diamond",
		piece:"piece",
		groupusernum:"Number of people in the group chat",
		blessing:"good luck",
		notseluser:"No designated recipient user",
		noinputuser:"User not filled in for collection!",
		nonumerror:"The quantity of a single package cannot be less than 1",
		noinputnum:"Number of sent items not filled in'",
		noinputAmount:"Send quantity not filled in",
		robed:"Received already",
		robNomore:"Already collected",
		Expired:"Expired",
		giftrobinfo:"View collection details",
		usergift:"{0}'s gift box",
		robNuminfo:"Asdasd has received {0}/{1} gifts, totaling {2}/{3} quantities",
		robgood:"The best hand qi",
		norobuser:"Non designated users cannot claim it!",
		Loading:"Loading..",
		exclusivegift:"Exclusive gift box",
		notstare:"Not started yet",
		zhi:"To",
		startdate:"Start date",
		enddate:"End date",
		selectfriend:"Select",
		checkNumfriend:"{0} friends have been selected",
		invitesuc:"Invitation successful",
		now:"Today",
		yesterday:"Yesterday",
		LastWeek:"Last week",
		Lastmonth:"Last month",
		Lastthreemonths:"In the past three months",
		allmsg:"All messages",
		Offline:"Offline",
		activeNum:"Activation",
		activelevel:'{0} day activity level',
		activelevel2:'{0} day',
		activelevelph:'{0} day activity ranking',
		recordsendinfo:"Press and hold the spacebar to start speaking, press Esc to exit",
		LuckyGiftBox:"Lucky Gift Box",
		ExclusiveGiftBox:"Exclusive Gift Box",
		Numberofgift:"Number",
		FillNumber:"Fill in number",
		peoplegroup:"There are a total of {0} people in this group",
		ExclusiveUser:"Exclusive user",
		SelectUser:"Please select a user",
		mangbox:"gift box",
		blindsentout:"The gift box sent by {0}",
		giftopennot:"The gift box has been claimed",
		giftopenexpire:"Blind box has expired",
		tiao:"items",
		DiamondGiftBox:"Diamond Gift Box",
		groupsignin:"Group Signed in",
		signinday:"",
		jxsigninday:"Sign in continuously for {0} day",
		peoplesigned:"Today,{0} people signed in",
		signedin:"sign",
		voiceshort:"The recording time is too short",
		welcomeback:"Welcome Back",
		welcomeinner:"Welcome back! Please enter your details",
		account:"Account",
		mineaccount:"Account",
		inneraccount:"Please enter your account",
		password:"Password",
		innerpassword:"Please enter your password",
		login:"Login",
		noaccount:"Don't have an account yet?",
		registernow:"Sign Up",
		welcomeregister:"Welcome To Sign Up",
		passwordtip:"Passwords can be composed of numbers and English letters, with a length of no less than 6 characters",
		confirmpassword:"Confirm Password",
		pleaseconfirm:"Please confirm your password",
		registerandlogin:"Sign Up And Login",
		haveaccount:"Do you already have an account?",
		loginnow:"Login Now",
		loingusergc:"The length ranges from {0} to {1} characters",
		placerepassword:"Please enter your password again",
		passdifferent:"Two passwords are inconsistent",
		loginnameinfo:"The account name must consist of letters and numbers",
		loginout:"Login out",
		loginout_qr:"Are you sure to login out?",
		passtszf:"Password cannot contain any other special characters",
		recreation:"recreation",
		groupmanagement:"Group Management",
		removemembers:"Remove group members",
		allprohibitedspeech:"All prohibited speech",
		liftallbans:"Lift all bans",
		confirmliftallbans:"Are you sure to lift the ban on all employees?",
		liftallbanstips:"After everyone is banned from speaking, everyone in this group is prohibited from sending messages. Are you sure?",
		tips:"Tips",
		removememberstips:"Are you sure to remove the selected members from this group?",
		remove:"remove",
		membersselected:"{0} members have been selected",
		searchfriends:"Search for friends",
		administrators:"administrators",
		AccountDetails: "Account Details",
		ExpenditureDiamond:"Expenditure {0} diamonds",
		RevenueDiamonds:"Revenue {0} diamonds",
		RedEnvelopeExpired:"Expired automatic return",
		comeFrom:"come from",
		sendGroupgift:"Send out group gift boxes",
		SignReward:"Sign in reward",
		myaccount:"ACCOUNT",
		MyDiamonds:"My diamonds",
		YesterdayEarnings:"Yesterday's earnings",
		ViewDetails:"View Details",
		mynosigninfo:"You haven't signed yet, please write one"
	}
}

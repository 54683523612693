<template>
	<el-upload :action="'#'" :http-request="uploadToOSS" :accept="fileTypes==null?'':fileTypes.join(',')" :show-file-list="false"
		:disabled="disabled" :multiple="multiple" :before-upload="beforeUpload">
		<slot></slot>
	</el-upload>
</template>

<script>

import {uploadFile} from '@/api/oss.js'
export default {
	name: "fileUpload",
	data() {
		return {
			loading: null,
		}
	},
	props: {
		action: {
			type: String,
			default: ''
		},
		fileTypes: {
			type: Array,
			default: null
		},
		maxSize: {
			type: Number,
			default: null
		},
		showLoading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		multiple:{
			type: Boolean,
			default: false
		}
	},
	methods: {
		async uploadToOSS(file){
			//请求地址为空直接返回文件
			if(!this.action){
				this.$emit("success",file.file);
				return
			}
			if(this.showLoading) {
				this.loading = this.$loading({
					lock: true,
					text: this.$t('comm.uploading'),
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			}
			// 直传文件到OSS
			uploadFile(file.file).then((res)=>{
				if(res.res.status == 200){
					let data = {name:res.name,url:res.url};
					// 处理上传成功的结果
					this.$emit("success", data, file.file);
				}else{
					this.$emit("fail", e, file.file);
				}
			}).catch(e=>{
				this.$emit("fail", e, file.file);
			}).finally(() => {
				this.loading && this.loading.close();
			})
		},

		// onFileUpload(file) {
		// 	//请求地址为空直接返回文件
		// 	if(!this.action){
		// 		this.$emit("success",file.file);
		// 		return
		// 	}
		// 	// 展示加载条
		// 	if (this.showLoading) {
		// 		this.loading = this.$loading({
		// 			lock: true,
		// 			text: this.$t('comm.uploading'),
		// 			spinner: 'el-icon-loading',
		// 			background: 'rgba(0, 0, 0, 0.7)'
		// 		});
		// 	}

		// 	let formData = new FormData()
		// 	formData.append('file', file.file)
		// 	this.$http({
		// 		url: this.action,
		// 		data: formData,
		// 		method: 'post',
		// 		headers: {
		// 			'Content-Type': 'multipart/form-data'
		// 		}
		// 	}).then((data) => {
		// 		this.$emit("success", data, file.file);
		// 	}).catch((e) => {
		// 		this.$emit("fail", e, file.file);
		// 	}).finally(() => {
		// 		this.loading && this.loading.close();
		// 	})
		// },
		beforeUpload(file) {
			// 校验文件类型
			if (this.fileTypes && this.fileTypes.length > 0) {
				let fileType = file.type;
				let t = this.fileTypes.find((t) => t.toLowerCase() === fileType);
				if (t === undefined) {
					this.$message.error(this.$t('comm.fllecw')+`${this.fileTypes.join("、")}`);
					return false;
				}
			}
			// 校验大小
			if (this.maxSize && file.size > this.maxSize) {
				this.$message.error(this.$t('comm.filesizemsg')+`${this.fileSizeStr}!`);
				return false;
			}

			this.$emit("before", file);
			return true;
		}
	},
	computed: {
		fileSizeStr() {
			if (this.maxSize > 1024 * 1024) {
				return Math.round(this.maxSize / 1024 / 1024) + "M";
			}
			if (this.maxSize > 1024) {
				return Math.round(this.maxSize / 1024) + "KB";
			}
			return this.maxSize + "B";
		}
	}
}
</script>

<style>
</style>
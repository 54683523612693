<template>
	<div class="head-image" :style="avatarImageStyle" @click="showUserInfo($event)">
		<el-image  
			class="avatar-image" 
			v-if="url&&!isonerror" 
			:style="avatarImageStyle" 
			@error="onerror" 
			:src="url" 
			:srcList="this.id>0?[]:[url]" 
			 />
		<div class="avatar-text" v-show="!url||isonerror" :style="avatarTextStyle">{{name.substring(0,1).toUpperCase()}}</div>		
		<div v-show="online" class="online" :title="$t('comm.nowonline')"></div>
		<div v-if="showunline&&!online" class="Offline" :title="$t('comm.nowUnonline')"></div>
		<slot></slot>
	</div>
</template>

<script>

	export default {
		name: "headImage",
		data() {
			return {
				isonerror :false,
				colors:["#7dd24b","#c7515a","#db68ef","#15d29b","#85029b",
				"#c9b455","#fb2609","#bda818","#af0831","#326eb6"]
			}
		},
		props: {
			id:{
				type: [String, Number]
			},
			size: {
				type: Number,
				default: 50
			},
			url: {
				type: String
			},
			name:{
				type: String,
				default: "?"
			},
			showunline: {
				type: Boolean,
				default: false
			},
			online:{
				type: Boolean,
				default:false
			},
			canFriend:{//允许用户信息添加好友
				type: Boolean,
				default:true
			}
		},
		watch:{
			url(newval,oldval){
				if(newval!=oldval){
					this.isonerror = false
				}
			}
		},
		methods:{
			onerror(){
				//图标加载失败
				this.isonerror =true
			},
			showUserInfo(e){
				if(this.canFriend && this.id && this.id>0){
					this.$http({
						url: `/im/imUser/find/${this.id}`,
						method: 'get'
					}).then((user) => {
						this.$store.commit("setUserInfoBoxPos",e);
						this.$store.commit("showUserInfoBox",user);
					})
				}
			}
		},
		computed:{
			avatarImageStyle(){
				return `width:${this.size}px; height:${this.size}px;`
			},
			avatarTextStyle(){
				return `width: ${this.size}px;height:${this.size}px;
				color:${this.textColor};font-size:${this.size*0.6}px;`
			},
			textColor(){
				let hash = 0;
				for (var i = 0; i< this.name.length; i++) {
					hash += this.name.charCodeAt(i);
				}
				return this.colors[hash%this.colors.length];
			}
		}	
	}
</script>

<style scoped lang="scss">
	::v-deep{
		img{
			background-color: #f5f5f5;
		}
	}
	.head-image {
		position: relative;
		cursor: pointer;
		.avatar-image {
			position: relative;
			overflow: hidden;
			border-radius: 8px;
		}
		
		.avatar-text{
			background-color: #f2f2f2; /* 默认背景色 */
			border-radius: 8px; /* 圆角效果 */
			display: flex;
			align-items: center;
			justify-content: center;
		} 
		
		.online{
			position: absolute;
			right: 0px;
			bottom: 0;
			width: 11px;
			height: 11px;
			background: #67C23A;
			border-radius: 50%;
			border: 1px solid white;
		}
		.Offline{
			position: absolute;
			right: 0px;
			bottom: 0;
			width: 11px;
			height: 11px;
			background: #999999;
			border-radius: 50%;
			border: 1px solid white;
		}
	}
</style>

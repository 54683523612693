import * as $enums from './enums.js';
import  i18n  from '@/utils/lang/index.js';

const emoTextList = ['😀','😬','😁','😂','😃','😄','😅','😆','😌','😋',
	'🙃','🙂','😊','😉','😇','😍','😘','😗','😙','😚',
	'😜','😝','😛','🤗','🤩','😎','🤓','🧐','🤣','🤪',
	'🤑','😏','😶','😐','😑','😒','🙄','🤭','🤫','😕',
	'😔','😡','😠','😟','😞','😳','🤔','🙁','️😣','😖',
	'😫','😩','😤','😮','😧','😦','😯','😰','😨','😱',
	'🤯','🤬','😢','😥','😪','🤤','😓','😭','😵','😲',
	'😴','🤕','🤒','😷','🤧','🤮','🤐','🤥']

let transform = (content,size) => {
	return content
	// return content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi,(emoText)=>{return textToImg(emoText,size)} );
}

// 将匹配结果替换表情图片
let textToImg = (emoText,size) => {
	//不使用图片表情，改用文字表情
	return emoText;
}

let simplemsg  = (mes)=>{
	let type = mes.type
	let mescontent = mes.content
	if (type == $enums.MESSAGE_TYPE.IMAGE) {
		mescontent =  "["+i18n.t('comm.picture')+"]";
	} else if (type ==$enums.MESSAGE_TYPE.AUDIO) {
		mescontent = "["+i18n.t('comm.voice')+"]";
	} else if (type ==$enums.MESSAGE_TYPE.FILE) {
		mescontent = "["+i18n.t('comm.flle')+"]";
	} else if (type ==$enums.MESSAGE_TYPE.RED_WRAP) {
		mescontent = "["+i18n.t('comm.giftbox')+"]"
	} else if (type ==$enums.MESSAGE_TYPE.IMG_TEXT) {
		let contentobj = JSON.parse(mescontent)
		let imglength =contentobj.filter(item=>{return item.type == 1}).length
		let textobj =  contentobj.find(item=>item.type == 0)
		mescontent = `[${i18n.t('comm.picture')}*${imglength}]  ${textobj?.text||''}`;
	}else if(type == $enums.MESSAGE_TYPE.TIP_TEXT){
		try{
			let contenobj = JSON.parse(mescontent)
			return i18n.t('tiptext.'+contenobj.key,contenobj.body)
		}catch{
			return content
		}
	}
	if(!mescontent){
		return "";
	}
	return transform(mescontent)
}


let textToUrl = (emoText) => {
	return ""
	// let word = emoText.replace(/\#|\;/gi, '');
	// let idx = emoTextList.indexOf(word);
	// if(idx==-1){
	// 	return "";
	// }
	// let url = require(`@/assets/emoji/${idx}.gif`);
	// return url;
}

export default {
	emoTextList,
	transform,
	textToImg,
	textToUrl,
	simplemsg
}

<template>
    <div class="page">
      <template v-if="!webShow">
        <div class="page-title">{{$t('comm.recreation')}}</div>
        <div>
          <div class="center-body" v-for="item in discoverList">
            <img :src="item.image" class="img" @click="goLookDetails(item)"/>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="back_bt" @click="webShow =!webShow"><span class="iconfont icon-fanhui"></span><span>返回</span></div>
        <iframe :src="url" class="iframe-box"></iframe>
      </template> 
    </div>
</template>

<script>

	export default {
		name: "recreation",
		data() {
			return {
        discoverList: [],
        url: null,
        webShow: false,
			}
		},
    created() {
      this.listDiscover();
    },
		methods: {
      listDiscover(){
        this.$http({
          url: `/im/discover/selectDiscoverList`,
          method: 'get',
        }).then((res) => {
          this.discoverList = res;
        })
      },
      goLookDetails(item) {
        this.url = item.url;
        this.webShow = true;
      },
		},
		computed: {

		}
	}
</script>

<style scoped lang="scss">
.page {
  position: relative;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  .iframe-box{
    width: 816px;
    height: 100%;
    margin: 0px auto;
    border: none;
  }
  .back_bt{
    position: absolute;
    top: 20px;
    left: 10%;
    background-color: #fff;
    color: #5B5C95;
    border-radius: 10px;
    height: 48px;
    width: 48px;
    display: flex;
    box-shadow: 0px 0px 4px #888888;
    flex-direction: column;
    .icon-fanhui{
      font-size: 20px;
      
    }
    span{
      line-height: 20px;
      font-size: 12px;
      margin:auto;
    }
    &:hover{
      background-color: #F5f5f5;
    }
  }
}
.page-title {
  margin: 24px 0px 0px 32px;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}

.center-body {
  width: 816px;
  height: 316px;
  margin: 0px auto 24px auto;
  background: #D8D8D8;
  border-radius: 12px;
  overflow: hidden;
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>

import enLocale from 'element-ui/lib/locale/lang/en'//这里先用英文
export default {
    ...enLocale,
    date:{
        just:"सिर्फ",
        minutesago:"मिनट पहले",
        yesterday:"कल",
        Monday:"सोमवार",
		Tuesday:"मंगलवार",
		Wednesday:"बुधवार",
		Thursday:"गुरुवार",
		Friday:"शुक्रवार",
		Saturday:"शनिवार",
		Sunday:"रविवार",
    },
    request:{
            httpbak:"एचटीटीपी निवेदन विधि गलत है",
            netbak:"सर्वर कुछ छोटे मुद्दे से मिले हैं, कृपया बाद फिर कोशिश करें",
            serverbak:"सर्वर मौजूदा निवेदन के लिए आवश्यक फंक्शन को समर्थन नहीं करता",
            quitonline:"आप दूसरे जगह में लॉग आया है और लॉग आउट करने के लिए बल्कित होगा",
            quitonlinetitle:"आवश्यक ऑफलाइन सूचना",
            disconnected:"कनेक्शन डिस्कनेक्ट किया गया है, फिर से कनेक्ट करने की कोशिश करें"
    },
    tiptext:{
		welcome:"समूह संवाद में जोड़ने के लिए {0} स्वागत है",
		joinGroup:"आप समूह के वार्तालाप में जोड़े हैं",
		quitGroup:"आप समूह चैट से बाहर हो गया है",
		loseGroup:"आप समूह चैट से हटाया गया है",
		askGroup:"{0} समूह चैट में जोड़ने के लिए {1} को आमन्त्रित करे",
	},
    comm:{
        chat:"चैट",
        groupchat:"समूह चैट",
        channel:"चैनल",
        unreadnum:"{0} अपठाया प्रविष्टि",
        search:"खोजें",
        addfriend:"मित्र जोड़ें",
        Receivingmsg:"संदेश प्राप्त कर रहा है",
        confirm:"निर्धारित करें",
        cancel:"रद्द करें",
        confirmDeltitle:"समाप्ति पुष्टि करें?",
        confirmDelmsg:"क्या आप निश्चित है कि आप {0} के साथ मित्रसभ को समाप्त करना चाहते हैं?",
        jcfriends:"{0} के साथ दोस्त संबंध समाप्त हो गया है",
        uploading:"अपलोड किया जा रहा है",
        sendfail:"भेजने में असफल",
        All:"पूर्ण",
        read:"पढ़ें",
        Unread:"पढ़े नहीं",
        Allread:"सभी पढ़ें",
        personread:"{0} लोग पढ़े हैं",
        onSendFail:"फ़ाइल भेजने में असफल है और वर्तमान स्वचालित पुनरावृत्ति को समर्थन नहीं करता है. इसे हाथ से पुनरावृत्ति करने के लिए स",
        recall:"हटाएँ",
        delete:"मिटाएँ",
        top:"ऊपर",
        atme:"[किसी मुझे]",
        atAll:"[सभी सदस्य]",
        Reachedtop:"शीर्ष पर पहुँचा गया",
        Groupname:"समूह चैट नाम",
        GroupRemark:"समूह चैट नोट",
        groupgg:"समूह निर्णय",
        GroupLeader:"समूह नेता",
        Remark:"टिप्पणी",
        noset:"सेट नहीं",
        Submit:"भेजें",
        exitgrouup:"समूह संवाद से बाहर हो",
        Edit:"संपादन",
        editsuccess:"सफलतापूर्वक परिवर्धित",
        exitgroupmsg:"समूह से बाहर होने के बाद, आप समूह से संदेश प्राप्त नहीं करेंगे। क्या आप निश्चित है कि आप बाहर होना चाहते हैं?",
        exitedgroup:"आप समूह चैट से बाहर हो गया है",
        confirmexit:"बाहर पुष्टि करें",
        allmembers:"सभी सदस्य",
        groupinfo:"समूह सूचना",
        exitchannel:"चैनल बाहर होना",
        Emoji:"एक्सप्रेशन",
        Sendpictures:"छवि भेजें",
        sendfiles:"फ़ाइल भेजें",
        Receipt:"संदेश प्राप्त करें",
        Chathistory:"चैट इतिहास",
        send:"भेजें",
        delmsg:"संदेश हटाएँ",
        confirmdelmsg:"क्या आप संदेश मिटाने के लिए यकीन है?",
        delsuccess:"सफलतापूर्वक मिटाएँ",
        recallmsg:"संदेश हटाएँ",
        confirmrecallmsg:"संदेश पुष्टि करें?",
        remsg:"आप एक संदेश छोड़ दिया",
        recalledmsg:"संदेश हटाया गया है",
        exitChannelmsg:"चैनल बाहर होने के बाद, आप कभी चैनल से संदेश नहीं पाएगा. क्या आप निश्चित है कि आप बाहर होना चाहते हैं?",
        exitsuccess:"सफलतापूर्वक बाहर निकलें",
        fllecw:"फ़ाइल प्रारूप गलत है. कृपया निम्न फ़ॉर्मेट में फ़ाइल अपलोड करें:",
        filesizemsg:"फ़ाइल आकार अधिक नहीं कर सकता",
        nowonline:"उपयोक्ता वर्तमान ऑनलाइन है",
        nickname:"उपनाम",
        sign:"स्वचालित",
        sendmessage:"संदेश भेजें",
        addfriends:"मित्र के रूप में जोड़ें",
        AddSuccess:"सफलतापूर्वक जोड़ गया, अन्य पार्टी तुम्हारा दोस्त हो गया",
        viewinfo:"जानकारी देखें",
        Modifyremarks:"टिप्पणी",
        delfriends:"मिटाएँ",
        inputid:"कृपया अपने दोस्त ID भरें",
        information:"जानकारी",
        Gender:"जनर",
        male:"पुरुष",
        female:"महिला",
        Setting:"सेटअप",
        Avatar:"शीर्ष पोर्ट्रेट",
        Pertags:"व्यक्तिगत हस्ताक्षर",
        Plename:"कृपया उपनाम भरें",
        language:"भाषा",
        usernospeak:"रोका गया बात",
        groupMute:"समूह को रोका नहीं गया है",
        disgroupMute:"समूह को बात करने से रोका गया है",
        userMute:"तुम्हें बाहर नहीं किया गया है",
        disuserMute:"आपको बात करने से रोका गया है",
        Offlinetime:"ऑफलाइन समय",
        clearAllTopMsg:"सभी पाइन्ड संदेश साफ करें",
        cancelAllTopMsg:"सभी पाइन्ड संदेश रद्द करें",
        chatlist:"सत्र सूची",
        friend:"मित्र",
        translateTo:"अग्रसारित करें",
        selFriendNum:"{0} संपर्क चुने गए हैं",
        transMsgNum:"संदेश अग्रसारित कर रहा है",
        from:"से आएँ",
        leaveToMsg:"संदेश छोड़ रहा है.",
        showyy:"संदर्भ दिखाएँ",
        hiedyy:"संदर्भ छुपाएँ",
        topMsg:"उप्पर जानकारी",
        jyNotSend:"तुम्हें समूह नेता द्वारा रोका गया है और अस्थायी रूप से संदेश भेजने में असमर्थ है!",
        reply:"जवाब",
        sendPlaceholder:"भेजने के लिए भरें, शिफ्ट+पंक्ति ब्रेक में प्रविष्ट करें",
        topMsgNum:"{0} पिन किया गया संदेश",
        del_onlyme:"सिर्फ मेरे लिए जानकारी मिटाएँ",
        del_group_infom:"हर की जानकारी से मिटाएँ",
        del_friend_infom:"मुझे और दूसरे पार्टी के लिए मिटाएँ",
        placeCheckdata:"कृपया पहले डाटा जाँचें!",
        yyNoExist:"संदर्भ संदेश मौजूद नहीं है",
        picture:"छवि",
        flle:"फ़ाइल",
        ToXXFriendhistory:"{0} के साथ चैट इतिहास",
        ToXXGrouphistory:"{0} के साथ चैट इतिहास",
        copy:"नकल",
        CancelTop:"उप्पर स्थान रद्द करें",
        transmit:"आगाँ",
        transmitmsg:"संदेश",
        removeGroup:"इस समूह से हटाएँ",
        setnobb:"रोका गया बात",
        setclearbb:"बात के बारे में बंद करें",
        download:"डाउनलोड",
        mutilple:"बहुत विकल्प",
        othermsgdelinfo:"अन्य लोगों के संदेश मिटाने के लिए अनुमति नहीं है",
        TOPMsg_group:"समूह सदस्यों को सूचना बिना टॉप अप करें",
        TOPMsg_group_infom:"टॉप अप और समूह सदस्यों को सूचित करें",
        TOPMsg_friend:"सिर्फ मेरे ऊपर रखें",
        TOPMsg_friend_infom:"मेरे ऊपर और दूसरे पार्टी पर स्थापित",
        TickGroupinfo:"उपयोक्ता {0} समूह चैट से बाहर काटा गया",
        userNobb_info:"उपयोक्ता {0} को रोका गया है",
        userbb_info:"उपयोक्ता {0} अस्वीकारित किया गया है",
        copysuc:"कॉपी सफल",
        online:"पंक्ति",
        showmore:"बेसी विस्तारित करें",
        hidemore:"इसे दूर रखें",
        remark_inputinfo:"समूह के वार्तालाप में टिप्पणी सिर्फ अपने लिए दृश्य हैं",
        notice_inputinfo:"यह एक समूह के लिए परिचय है",
        group_aliasName:"मैं इस समूह नाम में हूँ",
        group_info:"समूह परिचय",
        findhistory:"चैट इतिहास के लिए खोजें",
        chatnobb:"बिना इंटरप्शन संदेश",
        chattop:"शीर्ष पर चैट करें",
        clearhistory:"चैट इतिहास साफ करें",
        confirm_delAllhistory:"क्या आप सभी चैट रेकॉर्ड मिटाने के लिए यकीन है?",
        togetherDel:"अनुरूप मिटाएँ {0}",
        findGroupUser:"समूह सदस्य खोजें",
        Tobottom:"नीचे पर",
        bj_read:"पढ़े हुए रूप में चिह्नित करें",
        bj_Noread:"नपढ़ा चिह्नित करें",
        set_disturb:"बिना इंटरप्शन संदेश",
        clear_disturb:"रद्द नहीं करें",
        remove_char:"वार्तालाप सूची मिटाएँ",
        topped:"उप्पर स्थापित",
        inputInfoCharacters:"कृपया प्रविष्ट करें {0}- {1} एक अक्षर",
        nowUnonline:"उपयोक्ता वर्तमान ऑफ़लाइन है",
        countChat:"{0} सत्र का कुल",
        setsuccess:"सफल सेटिंग",
        signtis:"दूसरी पार्टी बहुत लम्बी है और अभी तक हस्ताक्षर नहीं है ~",
        myGroupailas:"मेरा समूह उपनाम",
        contacts:"संपर्क",
        groupdetails:"समूह विवरण",
        frienddetails:"विवरण",
        groupuser:"समूह सदस्य",
        userinfo:"व्यक्तिगत जानकारी",
        giftbox:"गिफ्ट बाक्स",
        sendgiftbox:"गिफ्ट बाक्स",
        voice:"आवाज",
        sending:"भेज रहा है",
        signsuccessful:"सफलतापूर्वक हस्ताक्षर करें",
        signed:"हस्ताक्षरित",
        inputnum:"वस्तुओं की संख्या में भरें",
        inputAmount:"मात्रा में भरें",
        selectuser:"कृपया एक उपयोक्ता चुनें",
        diamond:"ईंट",
        piece:"व्यक्तिगत",
        groupusernum:"समूह चैट में लोगों की संख्या",
        blessing:"क्या आप खुश और सफल हो सकते हैं",
        notseluser:"संग्रह के लिए उपयोक्ता निर्दिष्ट नहीं है",
        noinputuser:"प्रयोक्ता संग्रह के लिए भरा नहीं है!",
        nonumerror:"एक पैकेज की मात्रा १ से कम नहीं होता",
        noinputnum:"भेजे गए वस्तुओं की संख्या भरी नहीं है",
        noinputAmount:"भेजे गए वस्तुओं की संख्या भरी नहीं है",
        robed:"पहले से प्राप्त",
        robNomore:"पहले से संग्रहित",
        Expired:"मियाद",
        giftrobinfo:"संग्रह विवरण देखें",
        usergift:"{0} के लिए गिफ्ट बाक्स",
        robNuminfo:"{0}/{1} टुक्रा प्राप्त हुए, कुल {2}/{3} मात्रा प्राप्त है",
        robgood:"सर्वोत्तम हाथ की",
        norobuser:"निर्देशित उपयोक्ताओं ने इसे प्रतिलिपि नहीं कर सकते!",
        Loading:"लोड किया जा रहा है",
        exclusivegift:"एक्स्क्लुसिव उपहार बक्सा",
        notstare:"अभी प्रारंभ नहीं है",
        zhi:"में",
        startdate:"प्रारंभ तिथि",
        enddate:"अंतिम तिथि",
        selectfriend:"संपर्क व्यक्ति चुनें",
        checkNumfriend:"{0} दोस्त चुना गया है",
        invitesuc:"निमन्त्रणा सफल",
        now:"आज",
        yesterday:"कल",
        LastWeek:"पिछले सप्ताह",
        Lastmonth:"पिछला महीना",
        Lastthreemonths:"पिछले तीन महीने में",
        allmsg:"सभी संदेश",
        Offline:"ऑफलाइन",
        activeNum:"क्रियाकलाप स्तर",
        activelevel:'{0} दिन क्रिया स्तर',
		activelevel2:'{0} दिन',
        activelevelph:'{0} दिन क्रिया रेण्डिंग',
        recordsendinfo:"बात प्रारंभ करने के लिए स्पेस-पट्टी को दबाएँ तथा पकड़ें, बाहर होने के लिए Esc को दबाएँ",
        LuckyGiftBox:"भाग्य गिफ्ट बाक्स",
		ExclusiveGiftBox:"एक्स्क्लुसिव उपहार बक्सा",
		Numberofgift:"उपहार बक्सों की संख्या",
		FillNumber:"वस्तुओं की संख्या में भरें",
		peoplegroup:"इस समूह में कुल {0} लोग हैं",
		ExclusiveUser:"एक्स्क्लुसिव प्रयोक्ता",
		SelectUser:"कृपया एक उपयोक्ता चुनें",
        mangbox:"गिफ्ट बाक्स",
        blindsentout:"{0}उपहार बक्सा भेजा गया",
		giftopennot:"मैं देर हूँ, कुछ नहीं बचा रहा है",
		giftopenexpire:"अंधा बक्सा मियाद समाप्त है",
        tiao:"वस्तुएँ",
        DiamondGiftBox:"डायमन्ड गिफ्ट बाक्स",
        groupsignin:"समूह जाँच- इन",
		signinday:"",
		jxsigninday:"{0} दिन के लिए सदैव प्रविष्ट करें",
		peoplesigned:"आज {0} लोग हस्ताक्षरित{0}人",
		signedin:"",
        voiceshort:"रेकार्डिंग समय बहुत छोटा है",
        welcomeback:"वापस स्वागत",
        welcomeinner:"वापस स्वागत है! कृपया अपने खाता जानकारी भरें",
        account:"खाता नाम",
        mineaccount:"खाता संख्या",
        inneraccount:"कृपया अपना खाता नाम भरें",
        password:"पासवर्ड",
        innerpassword:"कृपया अपना पासवर्ड भरें",
        login:"में जाओ",
        noaccount:"अभी एक खाता नहीं है?",
        registernow:"अब रेजिस्टर करें",
        welcomeregister:"ररेजिस्टर में स्वागत है",
        passwordtip:"पासवर्ड संख्या और अंग्रेजी अक्षरों से बना सकता है, 6 अक्षरों से कम नहीं लम्बाई के साथ",
        confirmpassword:"पासवर्ड यकीन करें",
        pleaseconfirm:"कृपया पासवर्ड पुष्टि करें",
        registerandlogin:"रेजिस्टर और लॉग इन करें",
        haveaccount:"क्या तुम्हारे पास पहले से एक खाता है?",
        loginnow:"अब लॉगिन करें",
        loingusergc:"लंबाई {0} से {1} अक्षरों से सीमा है",
        placerepassword:"कृपया अपना पासवर्ड फिर भरें",
        passdifferent:"दो विभिन्न पासवर्ड",
		loginnameinfo:"खाता नाम को अक्षर और संख्या से समाविष्ट होना चाहिए",
        loginout:"लॉगिन आउट",
		loginout_qr:"क्या आप सच्चे लगइन आउट करने के लिए?",
        passtszf:"पासवर्ड कोई अन्य विशेष अक्षर नहीं समाविष्ट कर सकता",
        recreation:"विवेदन",
        groupmanagement:"समूह प्रबंधन",
        removemembers:"समूह सदस्य मिटाएँ",
        allprohibitedspeech:"सभी रोकी गई बात",
        liftallbans:"सभी मार्गों को उठाएँ",
        confirmliftallbans:"क्या तुम सभी काम करनेवालों के लिए निश्चित हो सकते हो?",
        liftallbanstips:"इस समूह में किसी को संदेश भेजने से रोका जाता है. क्या तुम सच्चे हो?",
        tips:"सचेतक",
        removememberstips:"क्या आप निश्चित है कि चयनित सदस्यों को इस समूह से मिटाएँ?",
        remove:"हटाएँ",
        membersselected:"{0} सदस्य चुना गया है",
        searchfriends:"मित्रों के लिए खोजें",
        administrators:"प्रशासक",
        ails:"खाता विवरण",
		ExpenditureDiamond:"खर्च {0} ईंट",
		RevenueDiamonds:"{0} ईंट का अंक",
		RedEnvelopeExpired:"मियाद के बारे में उपहार बक्सा स्वचालित रूप से फिर दिया जाएगा",
		comeFrom:"से आएँ",
        sendGroupgift:"समूह दान बाक्स भेजें",
        SignReward:"बदला में हस्ताक्षर करें",
        myaccount:"मेरा खात",
        MyDiamonds:"मेरे ईंट",
		YesterdayEarnings:"कल की कमाई",
		ViewDetails:"विवरण देखें",
		mynosigninfo:"आप अभी हस्ताक्षर नहीं किया है, कृपया एक लिखें"
    }
}

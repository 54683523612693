import FingerprintJS from '@fingerprintjs/fingerprintjs' 

const getDeviceFingerprint =  async function() {
    //获取指纹唯一id
    let fingerprintId = localStorage.getItem('browserId')
    if(!fingerprintId){
        const fpPromise = await FingerprintJS.load();
        const result = await fpPromise.get();
        // Exclude a couple components
        const { fonts, languages, audio, ...components } = result.components;
        // Add a few custom components
        const extendedComponents = {
            ...components,
            foo: { value: 'whatever' },
        };
        fingerprintId = FingerprintJS.hashComponents(extendedComponents);
        localStorage.setItem('browserId', fingerprintId)
    }
    return fingerprintId;
}


const getOS = async function () {
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
    var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
    if (isMac) return "Mac";
    var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
    if (isLinux) return "Linux";
    if (isWin) {
        let ua = await navigator?.userAgentData?.getHighEntropyValues(["platformVersion"])
        if (navigator?.userAgentData?.platform === "Windows") {
            const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
            if (majorPlatformVersion >= 13) {
                return "Win11"
            }else if(majorPlatformVersion > 0){
                return "Win10"
            }
        }
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista= sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
        var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
        if (isWin10) return "Win10"
        var isWin11 = sUserAgent.includes('Windows NT 10.0.22') || sUserAgent.includes('Windows NT 10.0.23') 
        if (isWin11) return "Win11";
        return  "Win"
    }
    return "other";
}

export default {
    getDeviceFingerprint,getOS
};
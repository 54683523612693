<template>
	<el-container class="home-page">
		<el-aside width="72px" class="navi-bar">
			<mine-info>
				<div class="user-head-box">
					<div class="user-head-image">
						<head-image class="head-image" :name="$store.state.userStore.userInfo.nickName"
							:url="$store.state.userStore.userInfo.headImage" :size="48">
						</head-image>
					</div>
				</div>
			</mine-info>
			<el-menu :default-active="selchar"  background-color="#202020">
				<el-menu-item index ="chat" :title="$t('comm.chat')"  @click="menusel('chat')">
					<span v-if="selchar=='chat'" class="iconfont icon-quanbuhuihua1" ></span>
					<span v-else class="iconfont icon-quanbuhuihua"></span>
					<div v-show="unreadCount > 0" class="unread-text">{{ showunreadCount }}</div>
				</el-menu-item>
				<el-menu-item index ="friend" :title="$t('comm.contacts')"  @click="menusel('friend')">
					<span v-if="selchar=='friend'" class="iconfont icon-lianxiren1" ></span>
					<span v-else class="iconfont icon-lianxiren"></span>
				</el-menu-item>
				<el-menu-item index ="channel" :title="$t('comm.channel')" @click="menusel('channel')">
					<span v-if="selchar=='channel'" class="iconfont icon-pindao1" ></span>
					<span v-else class="iconfont icon-pindao"></span>
				</el-menu-item>
				<el-menu-item index ="recreation" :title="$t('comm.recreation')" @click ="menusel('recreation')">
					<span v-if="selchar=='recreation'" class="iconfont icon-faxian" ></span>
					<span v-else class="iconfont icon-faxian1"></span>
				</el-menu-item>
			</el-menu>
			<span v-if="!is_no_login" @click="tickLogin" class="iconfont icon-tuichu"></span>
		</el-aside>
		<el-main class="content-box">
			<template v-if="!isrecreation">
				<router-view></router-view>
			</template>
			<Recreation v-if="is_loadfx" v-show="isrecreation"></Recreation>
		</el-main>
		<user-info v-show="uiStore.userInfo.show" :pos="uiStore.userInfo.pos" :user="uiStore.userInfo.user"  :isgroup="isActivateGroup"  @close="$store.commit('closeUserInfoBox')"></user-info>
	</el-container>
</template>

<script>
	import HeadImage from '../components/common/HeadImage.vue';
	import mineInfo from '../components/mine/mine-info.vue';
	import UserInfo from '../components/common/UserInfo.vue';
	import Recreation from './Recreation.vue';

	import {realogin} from '@/api/httpRequest.js'

	export default {
		components: {
			HeadImage,
			UserInfo,
			Recreation,
		 	mineInfo
		},
		data() {
			return {
				lastPlayAudioTime: new Date().getTime() - 1000,
				selchar : 'chat',
				isinit:false,
				is_loadfx:false,//是否首次不加载
				isrecreation:false,//是否显示发现页
				is_no_login:process.env.VUE_APP_ISNOLOGIN=='1'
			}
		},
		methods: {
			menusel(route){
				this.selchar =route;
				if(route=='recreation'){
					this.is_loadfx = true
					this.isrecreation=true;
				}else{
					this.$router.push('/home/'+route);
					this.isrecreation=false;
				}
			},
			tickLogin(){
				this.$confirm(this.$t('comm.loginout_qr'), this.$t('comm.loginout'), {
					confirmButtonText: this.$t('comm.confirm'),
					cancelButtonText:  this.$t('comm.cancel'),
					type: 'warning'
				}).then(() => {
					this.$http({
						url: "/auth/logout",
						method: "DELETE",
					}).then(()=>{
						this.$store.dispatch("unload")
						localStorage.removeItem("accessToken");
						this.$router.replace("/login");
					})
				})
			},
			beinit(gid){
				this.$http({
					url: `/im/groupMember/joinGroupMember/${gid}`,
					method: 'get',
				}).then((res) => {
					if(this.isinit){
						this.$store.dispatch("load").then(()=>{this.addlinkchat(res)})
					}else{
						this.init(()=>{this.addlinkchat(res)})
					}
					this.$router.push('/home/chat')
				})
			},
			//通过链接添加好友 ，放入到聊天列表
			addlinkchat(res){
				if(res.groupIds&&res.groupIds.length>0){
					res.groupIds.forEach((gid)=>{
						this.$store.commit("aadGroupChat",gid)
					})
				}
				if(res.serviceId){
					this.$store.commit("aadFriendChat",res.serviceId)
					this.$store.commit("activeChat", 0);
				}
			},
			//刷新token时间
			refreshtoken(){
				let expires_in = localStorage.getItem("expires_in")
			    let	loginInfo = {}
				let sy_time = 0
				let nowTime = Date.now()
				if(expires_in){
					loginInfo = JSON.parse(expires_in)
					let gq_ts = !loginInfo.ts?0:(loginInfo.expires_ss||loginInfo.expires_in)*60000+loginInfo.ts//token过期时间 时间戳
					sy_time = gq_ts-nowTime>0?gq_ts-nowTime:0//剩余过期时间 毫秒
				}else{
					loginInfo.expires_in = 21600
				}
				//剩余小于2天过期调用token延长接口
				if(sy_time<2*24*60*1000){
					this.$http({
						url: "/auth/refresh",
						method: 'POST'
					}).then(()=>{
						loginInfo.expires_ss = (sy_time==0?0:Math.floor(sy_time/60000))+loginInfo.expires_in
						loginInfo.ts = nowTime
						localStorage.setItem("expires_in",JSON.stringify(loginInfo));
					})
				}
			},
			init(callback) {
				this.isinit = true
				let isfirstload = false
				this.$store.dispatch("load").then(() => {
					callback&&callback()
					//更新刷新token
					this.refreshtoken()
					// ws初始化
					this.$wsApi.connect(process.env.VUE_APP_WS_URL, localStorage.getItem("accessToken"));
					this.$wsApi.onConnect(() => {
						// 加载离线消息
						this.pullPrivateOfflineMessage(this.$store.state.chatStore.privateMsgMaxId);
						this.pullGroupOfflineMessage(this.$store.state.chatStore.groupMsgMaxId);
						if(isfirstload){
							this.$store.dispatch("reloadUser")
						}else{
							isfirstload =true
						}
					});
					this.$wsApi.onMessage((cmd, msgInfo) => {
						if (cmd == 2) {
							// 关闭ws
							this.$wsApi.close(3000)
							// 异地登录，强制下线
							localStorage.removeItem("accessToken");
							this.$alert(this.$t('request.quitonline'),this.$t('request.quitonlinetitle'), {
								confirmButtonText:this.$t('comm.confirm'),
								callback: action => {
									this.$store.dispatch("unload")
									this.$router.replace("/login");
								}
							});
						} else if (cmd == 3) {
							// 插入私聊消息
							this.handlePrivateMessage(msgInfo);
						} else if (cmd == 4) {
							// 插入群聊消息
							this.handleGroupMessage(msgInfo);
						}
					});
					this.$wsApi.onClose((e) => {
						console.log(e);
						if (e.code != 3000) {
							// 断线重连
							this.$message.error(this.$t('request.disconnected'));
							this.$wsApi.reconnect(process.env.VUE_APP_WS_URL, localStorage.getItem("accessToken"));
						}
					});
					
				}).catch((e) => {
					console.log("初始化失败", e);
				})
			},
			pullPrivateOfflineMessage(minId) {
				this.$http({
					url: "/im/privateMessage/pullOfflineMessage?minId=" + minId,
					method: 'get'
				});
			},
			pullGroupOfflineMessage(minId) {
				this.$http({
					url: "/im/groupMessage/pullOfflineMessage?minId=" + minId,
					method: 'get'
				});
			},
			handlePrivateMessage(msg) {
				// 消息加载标志
				if (msg.type == this.$enums.MESSAGE_TYPE.LOADDING) {
					this.$store.commit("loadingPrivateMsg", JSON.parse(msg.content))
					return;
				}
				// 消息已读处理，清空已读数量
				if (msg.type == this.$enums.MESSAGE_TYPE.READED) {
					this.$store.commit("resetUnreadCount", {
						type: 'PRIVATE',
						targetId: msg.recvId
					})
					return;
				}
				// 消息回执处理,改消息状态为已读
				if (msg.type == this.$enums.MESSAGE_TYPE.RECEIPT) {
					this.$store.commit("readedMessage", {
						friendId: msg.sendId
					})
					return;
				}
				// 标记这条消息是不是自己发的
				msg.selfSend = msg.sendId == this.$store.state.userStore.userInfo.id;
				// 好友id
				let friendId = msg.selfSend ? msg.recvId : msg.sendId;
				//是否双向删除
				if(msg.type == this.$enums.MESSAGE_TYPE.DEL_CHAT){
					if(!msg.selfSend){
						this.$store.commit("deleteChat", {type:'PRIVATE',targetId:friendId})
						this.$store.commit("removeFriend",friendId);
					}
					return;
				}
				//添加好友，及时将好友添加到列表,不需要添加消息
				if (msg.type == this.$enums.MESSAGE_TYPE.ADD_FRIEND) {
					if(!msg.selfSend){
						this.loadFriendInfo(friendId).then((friend) => {
							let chatInfo = {
								type: 'PRIVATE',
								targetId: friend.id,
								showName: friend.friendRemark||friend.nickName,
								headImage: friend.headImage,
								disturbing: friend.disturbing,
							};
							this.$store.commit("openChat", chatInfo);
						})
					}
					return;
				}
				this.loadFriendInfo(friendId).then((friend) => {
					this.insertPrivateMessage(friend, msg);
				})
			},
			insertPrivateMessage(friend, msg) {
				// webrtc 信令
				if (msg.type >= this.$enums.MESSAGE_TYPE.RTC_CALL_VOICE &&
					msg.type <= this.$enums.MESSAGE_TYPE.RTC_CANDIDATE) {
					return;
				}
				let chatInfo = {
					type: 'PRIVATE',
					targetId: friend.id,
					showName:  friend.friendRemark || friend.nickName,
					headImage: friend.headImage,
					disturbing: friend.disturbing,
				};
				// 打开会话
				this.$store.commit("openChat", chatInfo);
				// 插入消息
				this.$store.commit("insertMessage", msg);
				// 播放提示音
				if (!msg.selfSend && msg.status != this.$enums.MESSAGE_STATUS.READED) {
					//消息免打扰
					if(friend.disturbing!=1){
						this.playAudioTip();
					}
				}
			},
			handleGroupMessage(msg) {
				// 消息加载标志
				if (msg.type == this.$enums.MESSAGE_TYPE.LOADDING) {
					this.$store.commit("loadingGroupMsg", JSON.parse(msg.content))
					return;
				}
				// 消息已读处理
				if (msg.type == this.$enums.MESSAGE_TYPE.READED) {
					// 我已读对方的消息，清空已读数量
					let chatInfo = {
						type: 'GROUP',
						targetId: msg.groupId
					}
					this.$store.commit("resetUnreadCount", chatInfo)
					return;
				}
				if(msg.type == this.$enums.MESSAGE_TYPE.DEL_CHAT){
					let targetId = msg.groupId
					this.$store.commit("deleteChat", {type:'GROUP',targetId:targetId})
					this.$store.commit("removeGroup",targetId);
					return;
				}
				// 消息回执处理
				if (msg.type == this.$enums.MESSAGE_TYPE.RECEIPT) {
					// 更新消息已读人数
					let msgInfo = {
						id: msg.id,
						groupId: msg.groupId,
						readedCount: msg.readedCount,
						receiptOk: msg.receiptOk
					};
					this.$store.commit("updateMessage", msgInfo)
					return;
				}
				// 标记这条消息是不是自己发的
				msg.selfSend = msg.sendId == this.$store.state.userStore.userInfo.id;
				this.loadGroupInfo(msg.groupId).then((group) => {
					// 插入群聊消息
					this.insertGroupMessage(group, msg);
				})
			},
			insertGroupMessage(group, msg) {			
				//禁言/解禁判断
				if(msg.type>=40&&msg.type<44){
					//更新群禁言状态
					if(msg.type==this.$enums.MESSAGE_TYPE.DIS_GROUP_SPEAK){
						msg.content = this.$t('comm.disgroupMute')
						this.$store.commit("setGroupSpeak", {id:msg.groupId,groupMute:1})
					}
					if(msg.type==this.$enums.MESSAGE_TYPE.GROUP_SPEAK){
						msg.content = this.$t('comm.groupMute')
						this.$store.commit("setGroupSpeak", {id:msg.groupId,groupMute:0})
					}
					if(msg.type==this.$enums.MESSAGE_TYPE.DIS_USER_SPEAK){
						msg.content = this.$t('comm.disuserMute')
						this.$store.commit("setUserGroupSpeak", {id:msg.groupId,userid:this.$store.state.userStore.userInfo.id,groupMute:1})	
					}
					if(msg.type==this.$enums.MESSAGE_TYPE.USER_SPEAK){
						msg.content = this.$t('comm.userMute')
						this.$store.commit("setUserGroupSpeak", {id:msg.groupId,userid:this.$store.state.userStore.userInfo.id,groupMute:0})
					}
					//插入禁言消息
					this.$store.commit("insertMsgTip", msg)
					return
				}
				//小于10或者21打开会话
				if(msg.type<this.$enums.MESSAGE_TYPE.RECALL||msg.type==this.$enums.MESSAGE_TYPE.TIP_TEXT){
					let chatInfo = {
						type: 'GROUP',
						targetId: group.id,
						showName: group.remark||group.name,
						headImage: group.HeadImage,
						groupType: group.groupType,  //1:普通群,2:频道
						disturbing: group.disturbing,
					};
					// 打开会话
					this.$store.commit("openChat", chatInfo);
				}
				// 插入消息
				this.$store.commit("insertMessage", msg);
				if(msg.type>this.$enums.MESSAGE_TYPE.RECALL){//大于10的消息没有提示音
					return 
				}
				// 播放提示音
				if (!msg.selfSend&&group.disturbing!=1){
					this.playAudioTip();
				}
			},
			playAudioTip() {
				if (new Date().getTime() - this.lastPlayAudioTime > 1000) {
					this.lastPlayAudioTime = new Date().getTime();
					let audio = new Audio();
					let url = require(`@/assets/audio/tip.wav`);
					audio.src = url;
					audio.play();
				}

			},
			loadFriendInfo(id) {
				return new Promise((resolve, reject) => {
					let friend = this.$store.state.friendStore.friends.find((f) => f.id == id);
					if (friend) {
						resolve(friend);
					} else {
						this.$http({
							url: `im/imUser/find/${id}`,
							method: 'get'
						}).then((friend) => {
							this.$store.commit("addFriend", friend);
							resolve(friend)
						})
					}
				});
			},
			loadGroupInfo(id) {
				return new Promise((resolve, reject) => {
					let group = this.$store.state.groupStore.groups.find((g) => g.id == id);
					if (group) {
						resolve(group);
					} else {
						this.$http({
							url: `/im/imGroup/find/${id}`,
							method: 'get'
						}).then((group) => {
							resolve(group)
							this.$store.commit("addGroup", group);
						})
					}
				});
			},
			startinit(){
				this.selchar = this.$route.fullPath.split("/").pop()
				let gid = this.$route.query.gid
				if(gid){
					this.beinit(gid);
				}else{
					this.init()
				}
			}
		},
		computed: {
			uiStore() {
				return this.$store.state.uiStore;
			},
			unreadCount() {
				let unreadCount = 0;
				let chats = this.$store.state.chatStore.chats;
				chats.forEach((chat) => {
					if(chat.type!="GROUP"||chat.groupType!='2'){
						unreadCount += chat.unreadCount
					}
				});
				return unreadCount;
			},
			showunreadCount(){
				return this.unreadCount>99?"99+":this.unreadCount
			},
			groupStore() {
				return this.$store.state.groupStore;
			},
			//当前选中聊天是否为群聊  
			isActivateGroup(){
				let activeChat = this.$store.state.chatStore.activeChat
				return activeChat && activeChat.type=="GROUP"&&this.groupStore.activeGroup&&this.groupStore.activeGroup.id == activeChat.targetId
			},
		},
		watch: {
			unreadCount: {
				handler(newCount, oldCount) {
					let tip = newCount > 0 ? this.$t('comm.unreadnum',[newCount]) : "";
					this.$elm.setTitleTip(tip);
				},
				immediate: true
			},
			'$route': {
				handler: function (to, from) {
					if(to.path=='/home/chat'||to.path=='/home'){
						let gid = to.query.gid
						if(gid){
							this.beinit(gid);
						}
					}
				},
				deep: true
			}
		},
		mounted() {
			let accessToken = localStorage.getItem('accessToken')
			if(!accessToken&&this.is_no_login){
				realogin().then(()=>{
					this.startinit()
				})
			}else{
				this.startinit()
			}
		},
		unmounted() {
			this.$wsApi.close();
		}
	}
</script>

<style scoped lang="scss">
	.home-page{
		top: 8px;
		left: 0px;
		bottom: 8px;
		right: 8px;
		position: absolute;
		overflow: hidden;
		height: auto;
		.user-head-box{
			display: flex;
			margin-top: 30px;
		}
		.user-head-image{
			margin: auto;
			width: 56px;
			height: 56px;
			border: 1px solid #FFFFFF;
			align-self: center;
			display: flex;
			border-radius: 50%;
			.head-image{
				border-radius: 50%;
				margin: auto;
				overflow: hidden;
			}
		}
		.iconfont {
			margin: auto;
			font-size: 20px;
			color: #999999;
			line-height: 20px;
		}
		.icon-quanbuhuihua1,.icon-lianxiren1,.icon-pindao1,.icon-faxian{
			color: #FFF;
		}
	}
	.navi-bar {
		box-sizing: border-box;
		background: #202020;
		padding-top: 10px;
		margin-bottom:0px;
		display: flex;
		flex-direction: column;
		.icon-tuichu{
			color: #FFFFFF;
			font-size: 20px;
			margin-bottom: 20px;
			&:hover{
				transform: scale(1.2);
			}

		}
		.el-menu {
			border: none;
			flex: 1;
			.el-menu-item{
				margin: 20px 8px;
				padding: 0px !important;
				text-align: center;
				height: 48px;
				line-height: 48px;
				border-radius: 10px;
				display: grid;
				place-items: center;
				position: relative;
				span{
					margin: 0px;
				}
				.unread-text{
					position: absolute;
					top: 7px;
					left: 29px;
					height: 16px;
					min-width: 16px;
					background: #FF0003;
					border-radius: 8px;
					box-sizing: border-box;
					padding:0px 3px;
					border: 1px solid #FFFFFF;
					font-weight: 400;
					font-size: 10px;
					color: #FFFFFF;
					line-height: 14px;
				}

			}
			.is-active{
				background-color: rgba(255,255,255,0.3) !important;
			}
		}
		.el-menu-item{
			&:hover{
				background-color:rgba(255,255,255,0.1) !important;
			}
			span{
				width: 20px;
				height: 20px;
			}
		}
		.exit-box {
			position: absolute;
			width: 60px;
			bottom: 40px;
			color: #aaaaaa;
			text-align: center;
			cursor: pointer;

			.icon {
				font-size: 28px;
			}
			&:hover {
				color: white !important;
			}
		}
	}

	.content-box {
		padding: 0;
		background-color: #FFF;
		color: #333;
		text-align: center;
		border-radius: 40px;
	}
	::v-deep {
		aside {
			padding:0px;
			margin-bottom:0px;
			line-height:normal;
			box-sizing: content-box;
		}
		section {
			height: 100%;
		}

		::-webkit-scrollbar {
			display: none;
		}

		::-webkit-scrollbar-thumb {
			/*滚动条里面小方块*/
			border-radius: 2px;
			-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			background: #535353;
		}

		::-webkit-scrollbar-track {
			/*滚动条里面轨道*/
			-webkit-box-shadow: inset 0 0 5px transparent;
			border-radius: 2px;
			background: #ededed;
		}
	}

</style>

<template>
    <el-popover
        placement="right-start"
        width="328"
        :visible-arrow="false"
        @show="show"
        trigger="click">
        <div class="mine-info">
            <file-upload  class="avatar-uploader"
                :action="action" 
                :showLoading="true"
                :maxSize="maxSize"  
                @success="onUploadSuccess"
                :fileTypes="['image/jpeg', 'image/png', 'image/jpg','image/webp']">
                    <img v-if="userInfo.headImage" :src="userInfo.headImage" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </file-upload>
            <edit-string  class="mine-name" :value="userInfo.nickName" :min="0" :max="15" :placeholder="userInfo.nickName"  filed="nickName" @editafter="editafter"/>
            <span class="mine-id">{{userInfo.id}}</span>
            <edit-string  class="mine-sign" :iswrap="false" :value="userInfo.signature" :min="0" :max="200" :placeholder="userInfo.signature||$t('comm.mynosigninfo')"  filed="signature" @editafter="editafter"/>
            <div class="grey-line"> </div>
            <div class="mine-ammount">
                <span><i class="iconfont icon-qianbaozhanghu"></i>{{$t('comm.myaccount')}}</span>
                <el-popover
                    placement="right-start"
                    width="592"
                    :visible-arrow="false"
                    @show="showaccount"
                    trigger="click">
                    <div class="mine-account">
                        <div class="account-title">{{$t('comm.AccountDetails')}}</div>
                        <div class="title-line"></div>
                        <el-scrollbar class="scroll-box"> 
                            <template  v-for="row in datalist">
                                <div v-if="row.rowtype==0" class="row-time">
                                    <span>{{row.yearmonth}}</span>
                                    <span class="amount-info">{{$t('comm.ExpenditureDiamond',[row.zc_count])}} {{$t('comm.RevenueDiamonds',[row.sr_count])}}</span>
                                </div>
                                <div v-if="row.rowtype==1" class="row-item">
                                    <img v-if="row.type!=0&&row.recordType==1||row.recordType==2" class="headimage" src="@/assets/im-icons/icon-gift.svg"  loading="lazy" />
                                    <img v-else class="headimage" :src="row.imgUrl"  loading="lazy" />
                                    <div class="col-item marginanto" >
                                        <div class="from-user" v-if="row.recordType==2">{{$t('comm.RedEnvelopeExpired')}}</div>
                                        <div class="from-user" v-if="row.recordType==0" >{{$t('comm.comeFrom')}} {{row.remark}}</div>
                                        <div class="from-user" v-if="row.recordType==1" >{{$t('comm.sendGroupgift')}}</div>
                                        <div class="from-time">{{row.date}}</div>
                                    </div>
                                    <div class="col-item">
                                        <div class="amount-unm">{{row.amount_str}}</div>
                                        <div class="amount-type">{{row.sourceType==0&&$t('comm.DiamondGiftBox')||row.sourceType==1&&$t('comm.SignReward')}}</div>
                                    </div>
                                </div>
                            </template>
                        </el-scrollbar>
                    </div>
                    <span slot="reference" class="view-detailed">{{$t('comm.ViewDetails')}}</span>
                </el-popover>
            </div>
            <div class="mine-ammount-info">
                <div class="item-info">
                    <img  class="zs3" src="@/assets/im-icons/im-zs3.svg"  loading="lazy"/>
                    <div class="item-info-zs">
                        <span class="item-num">{{accountInfo.diamondAmount||0}}</span>
                        <span>{{$t('comm.MyDiamonds')}}</span>
                    </div>
                </div>
                <div class="col-line"></div>
                <div class="item-info">
                    <img  class="zs3" src="@/assets/im-icons/im-zs3.svg"  loading="lazy"/>
                    <div class="item-info-zs">
                        <span class="item-num">{{accountInfo.yesterdayEarnings||0}}</span>
                        <span>{{$t('comm.YesterdayEarnings')}}</span>
                    </div>
                </div>
            </div>
            <div class="select-info">
                <i class="iconfont icon-wode1"></i>
                <span>{{$t('comm.mineaccount')}}</span>
                <span style="margin-left:auto;margin-right:4px;color: #999999;">{{userInfo.userName}}</span>
            </div>
            <div class="select-info">
                <i class="iconfont icon-xingbie"></i>
                <span>{{$t('comm.Gender')}}</span>
                <el-select :value="userInfo.sex" @change="(val)=>{userupdate('sex',val)}">
                    <el-option :label="$t('comm.male')" :value="0"></el-option>
                    <el-option :label="$t('comm.female')" :value="1"></el-option>
                </el-select>
            </div>
            <div class="select-info">
                <i class="iconfont icon-yuyan"></i>
                <span>{{$t('comm.language')}}</span>
                <el-select v-model="lang"  @change="languagechange">
                    <el-option label="中文(简体)" value="zh_CN"></el-option>
                    <el-option label="English" value="en_US"></el-option>
                    <el-option label="हिंदी" value="hi_IN"></el-option>
                </el-select>
            </div>
        </div>
        <slot slot="reference"></slot>
    </el-popover>
</template>

<script>
	import FileUpload from "../common/FileUpload.vue";
    import i18n from '@/utils/lang/index.js';
    import EditString from "../common/EditString.vue";
	export default {
        name: "mine-info",
        components: {
			FileUpload,
            EditString
		},
        data() {
			return {
                visible:true,
                userInfo: {},
                maxSize: 100*1024*1024,
				action: "/file/upload",
                datalist:[],
                accountInfo:{},
                lang:null
            }
        },
        created(){
			this.lang = localStorage.getItem('locale') || 'zh_CN';
		},
		methods: {
            show(){
                let mine = this.$store.state.userStore.userInfo;
				this.userInfo = JSON.parse(JSON.stringify(mine));
                this.$http({
					url: '/im/propInfo/selectUserAccount',
					method: 'GET'
				}).then((accountInfo) => {
					this.accountInfo = accountInfo;
				})
            },
            onUploadSuccess(data, file) {
				this.userInfo.headImage = data.url;
                this.userupdate('headImage',data.url);
			},
            languagechange(){
				i18n.locale = this.lang
				localStorage.setItem('locale',this.lang)
			},
            editafter({filed,value}){
                this.userupdate(filed,value)
            },
            userupdate(field,value){
                let param = JSON.parse(JSON.stringify(this.userInfo));
                param[field] = value
                this.$http({
                    url: "/im/imUser/update",
                    method: "put",
                    data: param
                }).then(()=>{
                    this.userInfo[field] = value
                    this.$store.commit("setUserInfo",{...this.userInfo});
                    this.$emit("close");
                    this.$message.success(this.$t('comm.editsuccess'));
                })
            },


            showaccount(){
                this.queryAccountDetails()
            },
            queryAccountDetails(){
                this.$http({
                    url: '/im/propInfo/selectAccountDetails',
                    method: 'GET',
                }).then((res)=>{
                    let datalist = [];
                    let lastdata = {}
                    res.forEach(row => {
                        row.rowtype = 1
                        row.date = this.$date.formatDate(row.createTime*1000,"dd/M hh:mm:ss")
                        let yearmonth = this.$date.formatDate(row.createTime*1000,"M/yyyy")
                        if(yearmonth!= lastdata.yearmonth){
                            lastdata = {rowtype:0,yearmonth:yearmonth,sr_count:0,zc_count:0}
                            datalist.push(lastdata)
                        }
                        if(row.recordType==0||row.recordType==2){
                            row.amount_str='+'+row.amount
                            lastdata.sr_count+=row.amount
                        }else if(row.recordType==1){
                            row.amount_str='-'+row.amount
                            lastdata.zc_count+=row.amount
                        }
                        datalist.push(row)
                    });
                    this.datalist = datalist
			    })
            }
        }
    }
</script>

<style scoped lang="scss">
    .mine-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        .edit-string{
            ::v-deep{
                .content{
                    justify-content: center;
                }
            }
        }
        .avatar-uploader{
            width: 98px;
            height: 98px;
            border-radius: 16px;
            border: 1px solid #DCDFE6;
            display: flex;
            margin-top: 25px;
            .avatar,.avatar-uploader-icon{
                width: 80px;
                height: 80px;
                margin: 8px;
                background: #dddddd;
                border-radius: 12px;
                &.avatar-uploader-icon{
                    line-height: 80px;
                    font-size: 40px;
                    &:hover{
                        background: #d1d1d1;
                    }
                }
            }
        }
        .mine-name{
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
            margin-top: 8px;
            text-align: center;
            margin-left: 20px;
        }
        .mine-id{
            margin-top: 4px;
            font-size: 12px;
            color: #999999;
            line-height: 16px;
        }
        .mine-sign{
            margin-top: 8px;
            margin-bottom: 16px;
            font-size: 12px;
            color: #626569;
            line-height: 16px;
            margin-left: 20px;
        }
        .grey-line{
            height: 16px;
            width: 328px;
            background-color: #eee;
        }
        .mine-ammount{
            width: 328px;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left:16px;
            font-size: 14px;
            .icon-qianbaozhanghu{
                font-size: 16px;
                color: #7678ED;
                margin-right: 8px;
            }
            .view-detailed{
                padding-right: 16px;
                color:#7678ED;
                cursor: pointer;
            }
        }
        .mine-ammount-info{
            width: 100%;
            height: 64px;
            display: flex;
            .item-info{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                .zs3{
                    height: 35px;
                    height: 40px;
                }
                .item-info-zs{
                    margin-left: 8px;
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    color: #626569;
                    line-height: 16px;
                    text-align: center;
                    .item-num{
                        font-size: 28px;
                        color: #626569;
                        line-height: 28px;
                        margin-bottom: 4px;
                    }
                }
            }
            .col-line{
                width: 1px;
                background: linear-gradient(180deg, rgba(228, 228, 251, 0.2), rgba(228, 228, 251, 1), rgba(228, 228, 251, 1), rgba(228, 228, 251, 0.2));
            }
        }
        .select-info{
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333333;
            .iconfont{
                font-size: 20px;
                color:#7678ED;
                margin-left: 4px;
                margin-right: 8px;
            }
            .el-select{
                margin-left: auto;
                ::v-deep{
                    input{
                        width: 150px;
                        border: none;
                        text-align: right ;
                        color: #999999;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .mine-account{
        display: flex;
        flex-direction: column;
        height: 432px;
        .account-title{
            padding: 4px 0px 16px 4px;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 24px;
        }
        .title-line{
            width:592px;
            background-color:#EEEEEE;
            height: 1px;
            align-self: center;
        }
        .scroll-box{
            flex: 1;
            height: 0;
            ::v-deep{
				.el-scrollbar__wrap::-webkit-scrollbar{
					height:0px !important;
				}
			}
            .row-time{
                display: flex;
                font-size: 14px;
                color: #333333;
                align-items: center;
                justify-content: space-between;
                padding: 0 4px;
                height: 40px;
                position: sticky;
                top: -1px;
                background-color: #FFF;
                z-index: 1;
                .amount-info{
                    font-size: 12rpx;
                    color: #999999;
                }
            }
            .row-item{
                display: flex;
                align-items: center;
                padding: 0 4px;
                .headimage{
                    width: 48px;
                    height: 48px;
                    font-size: 48px;
                    border-radius: 16px;
                    background-color: #f5f5f5;
                    margin: 8px 12px 8px 0px;
                }
                .marginanto{
                    margin-right: auto;
                }
                .col-item{
                    display: flex;
                    flex-direction: column;
                    .from-user{
                        line-height: 20px;
                        font-size: 14px;
                        color: #333333;
                    }
                    .from-time{
                        margin-top: 8px;
                        font-size: 12px;
                        color: #999999;
                        line-height: 17px;
                    }
                    .amount-unm{
                        text-align: right;
                        font-weight: 500;
                        font-size: 14px;
                        color: #333333;
                        line-height: 20px;
                    }
                    .amount-type{
                        margin-top: 8px;
                        font-size: 12px;
                        color: #999999;
                        line-height: 17px;
                    }
                }
            }
        }
    }
</style>